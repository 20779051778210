import React, { useState, useEffect } from "react"
import { FcUpload } from "react-icons/fc"
import user from "../../../assets/images/users/empty-user.png"
import {
  Form,
  Input,
  Select,
  Upload,
  Row,
  Col,
  Collapse,
  Radio,
  Tag,
} from "antd"

import { Form as DropForm } from "reactstrap"
import Dropzone from "react-dropzone"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import Editor from "@draft-js-plugins/editor"
// import { Editor } from "react-draft-wysiwyg"
import createLinkifyPlugin from "@draft-js-plugins/linkify"
import createEmojiPlugin from "@draft-js-plugins/emoji"
import "@draft-js-plugins/emoji/lib/plugin.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from "draftjs-to-html"
import CustomToolTip from "pages/TaskList/components/customToolTip"
import toast from "react-hot-toast"

const linkifyPlugin = createLinkifyPlugin()
const emojiPlugin = createEmojiPlugin({
  useNativeArt: true,
})
const { EmojiSelect } = emojiPlugin

const plugins = [linkifyPlugin, emojiPlugin]

const { Option } = Select

const { TextArea } = Input
const { Panel } = Collapse

const defaultChannels = [
  {
    id: "all-channel",
    name: "All Channel",
  },
  {
    id: "LinkedIn",
    name: "LinkedIn",
  },
  {
    id: "Twitter",
    name: "Twitter",
  },
]

const PostForm = ({
  handleSelectAccounts,
  handleContentChange,
  selectedAccounts,
  content,
  socialAccounts,
  handleAddPhotos,
  editMode,
  files,
  campaigns,
}) => {
  const [uploadMediaType, setUploadMediaType] = useState(1)
  const [editorStates, setEditorStates] = useState(null)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [selectedFiles, setSelectedFiles] = useState([])
  const [channel, setChannel] = useState({
    id: "all-channel",
    name: "All Channel",
  })

  useEffect(() => {
    if (editMode) {
      setSelectedFiles(files)
      setEditorState(
        EditorState.createWithContent(ContentState.createFromText(content))
      )
    }
  }, [editMode])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    console.log("files", files)

    if (uploadMediaType === 1) {
      if (selectedFiles && selectedFiles.length) {
        setSelectedFiles([...selectedFiles, ...files])
      } else {
        setSelectedFiles(files)
      }
    } else {
      setSelectedFiles(files)
    }
  }

  useEffect(() => {
    if (selectedFiles && selectedFiles.length) {
      handleAddPhotos(selectedFiles, uploadMediaType)
    } else {
      handleAddPhotos([], uploadMediaType)
    }
  }, [selectedFiles])

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleRequest = ({ file, onSuccess }) => {
    console.log("request--->", file)
  }

  const handleSelectCampaign = event => {
    console.log("event", event)
  }

  const handleSelectURLShortener = event => {
    console.log("event", event)
  }

  const handleSelectGoals = event => {
    console.log("event", event)
  }

  const handleRemoveFile = file => {
    let index = selectedFiles.findIndex(f => f.preview === file.preview)
    if (index > -1) {
      selectedFiles.splice(index, 1)
      setSelectedFiles([...selectedFiles])
    }
  }

  const onEditorStateChange = editState => {
    if (editorStates && editorStates.length) {
      let index = editorStates?.findIndex(es => es.id === channel.id)
      console.log("index", index)
      if (index > -1) {
        let newEditorStates = [...editorStates]
        newEditorStates[index].state = editState
      }
    }

    setEditorState(editState)
  }

  useEffect(() => {
    if (editorState) {
      let content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      let contentStr = editorState.getCurrentContent().getPlainText()

      handleContentChange(content, contentStr)
    }
  }, [editorState])

  useEffect(() => {
    if (editorStates && editorStates.length) {
      let index = editorStates.findIndex(es => es.id === channel.id)
      if (index > -1) {
      }
      // let content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      // let contentStr = editorState.getCurrentContent().getPlainText()

      // handleContentChange(content, contentStr)
    }
  }, [editorStates])

  const handleUploadMediaChange = e => {
    setUploadMediaType(e.target.value)
    setSelectedFiles(null)
  }

  console.log("selectedAccounts", selectedAccounts, socialAccounts)

  const getAccountInfo = id => {
    return socialAccounts.find(socialAccount => socialAccount.id === id)
  }

  const handleChannelChange = defaultChannel => {
    setChannel({ ...defaultChannel })
  }

  const checkIsAccountSelected = id => {
    let isPresent = false
    if (socialAccounts && socialAccounts.length) {
      for (let i = 0; i < socialAccounts.length; i++) {
        if (socialAccounts[i].accountType === id) {
          isPresent = true
          break
        }
      }
    }
    return isPresent
  }

  return (
    <React.Fragment>
      {/* NOTE Select Accounts */}
      <div>
        <label>Select Accounts</label>
        <span className="text-danger ms-1">*</span>
        <Form.Item
          name="accounts"
          rules={[
            {
              required: true,
              message: "Please select one or more accounts",
            },
          ]}
        >
          <Select
            mode="multiple"
            disabled={editMode}
            showSearch
            allowClear
            placeholder="Select Accounts"
            style={{
              minHeight: "32px",
              border: "1px solid #d9d9d9",
              borderRadius: "6px",
              width: "100%",
            }}
            value={selectedAccounts ? selectedAccounts : undefined}
            bordered={false}
            dropdownStyle={{ borderRadius: "6px" }}
            onChange={handleSelectAccounts}
            optionFilterProp="children"
          >
            {socialAccounts
              ? socialAccounts.map(account => {
                  return (
                    <Option
                      key={account.id}
                      value={account.id}
                      label={account.firstName}
                    >
                      <div className="d-flex justify-content-startalign-items-start px-1">
                        <div>
                          <img
                            className="rounded-circle header-profile-user linkedin-dp me-1"
                            src={
                              account.profilePicURL
                                ? account.profilePicURL
                                : user
                            }
                          />
                        </div>
                        <div>
                          <div className="font-size-14 font-weight-semibold">
                            {account.firstName} {account.lastName}
                            <div
                              className="text-secondary font-size-12"
                              style={{
                                lineHeight: "1rem",
                              }}
                            >
                              {account.accountType}
                              <i className="fas fa-twitter"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Option>
                  )
                })
              : null}
          </Select>
        </Form.Item>
      </div>

      {/* NOTE Channels */}
      <div className="p-1 border border-1 rounded-3 mb-3 d-flex justify-content-start align-items-center d-none">
        <CustomToolTip
          text={
            <span>
              <i class="fad fa-info-circle me-2 d-block"></i>
            </span>
          }
          heading="Edit channel content."
          subText="First edit all channel content and then edit other channels"
        />
        <div
          onClick={() =>
            setChannel({
              id: "all-channel",
              name: "All Channel",
            })
          }
          className={`me-2 font-size-12 cursor-pointer p-1 rounded-3 ${
            channel.id === "all-channel" && "bg-channel"
          }`}
        >
          <i className="fal fa-globe"></i> All Channels
        </div>
        {defaultChannels
          ? defaultChannels.map(defaultChannel => {
              let isAccountSelected = checkIsAccountSelected(defaultChannel.id)
              if (isAccountSelected) {
                return (
                  <div
                    className="d-flex justify-content-start align-items-start px-1 me-2 cursor-pointer channel rounded-3 px-2"
                    onClick={() => {
                      handleChannelChange(defaultChannel)
                    }}
                    style={{
                      backgroundColor:
                        channel.id === defaultChannel.id
                          ? "#edf2f7"
                          : "transparent",
                    }}
                  >
                    <div>
                      {/* <img
                        className="rounded-circle header-profile-user linkedin-dp me-1"
                        src={
                          account.profilePicURL ? account.profilePicURL : user
                        }
                      /> */}
                    </div>
                    <div>
                      <div className="font-size-12 font-weight-semibold">
                        <div
                          className="text-secondary font-size-12"
                          style={{
                            lineHeight: "1rem",
                          }}
                        >
                          {defaultChannel.name}
                          <i className="fas fa-twitter"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })
          : null}
      </div>

      {/* NOTE Content */}
      <div>
        <label>Content</label>
        <span className="text-danger ms-1">*</span>
        <Tag className="ms-1 rounded-3 d-none">{channel.name}</Tag>
        <Form.Item name="content">
          <div className="post-content-editor">
            <Editor
              editorState={editorState}
              onChange={onEditorStateChange}
              plugins={plugins}
              placeholder="Enter your content here"
            />
            <div className="post-content-editor-footer">
              <EmojiSelect />
            </div>
          </div>
        </Form.Item>
      </div>

      {/* TODO */}
      <div className="mb-2">
        <Radio.Group
          value={uploadMediaType}
          disabled={editMode}
          onChange={handleUploadMediaChange}
        >
          <Radio value={1}>Photo</Radio>
          <Radio value={2}>Video</Radio>
        </Radio.Group>
      </div>
      <DropForm>
        <Dropzone
          onDrop={(acceptedFiles, fileRejections) => {
            handleAcceptedFiles(acceptedFiles)
            console.log("fileRejections", fileRejections)
            if (fileRejections && fileRejections.length) {
              let code = fileRejections[0].errors[0].code
              if (code === "file-too-large") {
                toast.error(
                  "File size is too large. Maximum file size allowed is 15MB",
                  {
                    position: "top-right",
                  }
                )
              }
            }
          }}
          multiple={uploadMediaType === 1 ? true : false}
          accept={uploadMediaType === 1 ? "image/*" : "video/mp4"}
          maxSize={15000000}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              style={{
                display: editMode
                  ? // || (uploadMediaType === 2 && selectedFiles?.length)
                    "none"
                  : "block",
              }}
            >
              <div
                className="mt-2 py-3 rounded-3 cursor-pointer upload-btn"
                {...getRootProps()}
                style={{
                  width: "150px",
                  textAlign: "center",
                }}
              >
                <input {...getInputProps()} />
                <div>
                  <img
                    src="https://img.icons8.com/color/28/000000/upload-to-cloud.png"
                    className="me-1"
                  />
                  Upload {uploadMediaType === 1 ? "Photos" : "Video"}
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        <div className="mt-3 d-flex flex-wrap" id="file-previews">
          {selectedFiles && selectedFiles.length
            ? selectedFiles.map((f, i) => {
                return (
                  <div className="m-1 border rounded-3" key={i + "-file"}>
                    <div className="p-2">
                      <div className="d-flex justify-content-start align-items-center">
                        <a
                          href={f.preview}
                          target="_blank"
                          className="text-dark d-block d-flex align-items-center font-weight-bold"
                        >
                          <div
                            className="mx-2 d-flex justify-content-center align-content-center"
                            style={{
                              width: "60px",
                            }}
                          >
                            {f.type.includes("image") ? (
                              <img
                                className="avatar-sm rounded bg-light"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                                alt={f.name}
                                src={f.preview}
                              />
                            ) : (
                              <video preload="metadata" width="60" height="40">
                                <source
                                  src={f.preview + "#t=0.5"}
                                  type="video/mp4"
                                ></source>
                              </video>
                            )}
                          </div>
                        </a>
                        <div span={4}>
                          {f.name}
                          <div className="font-size-10">
                            <strong>{f.formattedSize}</strong>
                          </div>
                        </div>

                        {editMode ? null : (
                          <div
                            onClick={() => handleRemoveFile(f)}
                            className="mx-2 cursor-pointer"
                          >
                            <i className="fal fa-trash text-danger"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })
            : null}
        </div>
      </DropForm>

      {/* NOTE Campaign and URL Shortener */}
      <Row gutter="24" className="mt-3">
        <Col span={12}>
          <label>Campaign</label>
          <span className="text-danger ms-1">*</span>
          <Form.Item
            name="campaign"
            rules={[
              {
                required: true,
                message: "Please select a campaign",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Select a Campaign"
              style={{
                height: "32px",
                border: "1px solid #d9d9d9",
                borderRadius: "6px",
                width: "100%",
              }}
              bordered={false}
              dropdownStyle={{ borderRadius: "6px" }}
              onChange={handleSelectCampaign}
              optionFilterProp="children"
            >
              {campaigns
                ? campaigns.map(campaign => {
                    return (
                      <Option key={campaign.id} value={campaign.id}>
                        {campaign.name}
                      </Option>
                    )
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} className="d-none">
          <label>URL Shortener</label>
          <Form.Item name="urlShortener">
            <Select
              showSearch
              allowClear
              placeholder="Select a URL Shortener"
              style={{
                height: "32px",
                border: "1px solid #d9d9d9",
                borderRadius: "6px",
                width: "100%",
              }}
              bordered={false}
              dropdownStyle={{ borderRadius: "6px" }}
              onChange={handleSelectURLShortener}
              optionFilterProp="children"
            >
              <Option key="1" value="spr.ly">
                spr.ly
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* NOTE Strategy */}
      <Collapse
        bordered={false}
        style={{
          background: "none",
        }}
        defaultActiveKey={["1", "2"]}
        className="d-none"
      >
        {/* NOTE Goals */}
        <Panel
          style={{
            border: "none",
          }}
          header={
            <div className="d-flex justify-content-start align-items-center w-100">
              <div
                style={{
                  width: "100px",
                  whiteSpace: "nowrap",
                }}
                className="font-weight-semibold"
              >
                Strategy
              </div>
              <div className="h-line"></div>
            </div>
          }
          key="1"
          className="mb-0"
        >
          <Form.Item name="goals">
            <div className="font-weight-semibold">Goals</div>
            <div className="mb-1">
              <small className="text-secondary">
                The objective or purpose of the content
              </small>
            </div>
            <Select
              mode="multiple"
              showSearch
              allowClear
              placeholder="Select one or more goals"
              style={{
                height: "32px",
                border: "1px solid #d9d9d9",
                borderRadius: "6px",
                width: "100%",
              }}
              bordered={false}
              dropdownStyle={{ borderRadius: "6px" }}
              onChange={handleSelectGoals}
              optionFilterProp="children"
            >
              <Option key="1" value="ibr">
                Increase Brand Awarness
              </Option>
            </Select>
          </Form.Item>
        </Panel>

        <Panel
          style={{
            border: "none",
          }}
          header={
            <div className="d-flex justify-content-start align-items-center w-100">
              <div
                style={{
                  width: "100px",
                  whiteSpace: "nowrap",
                }}
                className="font-weight-semibold"
              >
                Others
              </div>
              <div className="h-line"></div>
            </div>
          }
          key="2"
          className="site-collapse-custom-panel"
        >
          <div>
            <Row gutter={24} className="mb-2">
              <Col span={12}>
                <Form.Item name="contentType">
                  <label className="text-secondary">Content Type</label>
                  <Select
                    showSearch
                    allowClear
                    placeholder="Content Type"
                    style={{
                      height: "32px",
                      border: "1px solid #d9d9d9",
                      borderRadius: "6px",
                      width: "100%",
                    }}
                    bordered={false}
                    dropdownStyle={{ borderRadius: "6px" }}
                    optionFilterProp="children"
                  >
                    <Option key="1" value="ct1">
                      Content type 1
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="vehicle">
                  <label className="text-secondary">Vehicle</label>
                  <Select
                    showSearch
                    allowClear
                    placeholder="Vehicle"
                    style={{
                      height: "32px",
                      border: "1px solid #d9d9d9",
                      borderRadius: "6px",
                      width: "100%",
                    }}
                    bordered={false}
                    dropdownStyle={{ borderRadius: "6px" }}
                    optionFilterProp="children"
                  >
                    <Option key="1" value="v1">
                      Vehicle 1
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} className="mb-2">
              <Col span={12}>
                <Form.Item name="postFocus">
                  <label className="text-secondary">Post Focus</label>
                  <Select
                    showSearch
                    allowClear
                    placeholder="Post Focus"
                    style={{
                      height: "32px",
                      border: "1px solid #d9d9d9",
                      borderRadius: "6px",
                      width: "100%",
                    }}
                    bordered={false}
                    dropdownStyle={{ borderRadius: "6px" }}
                    optionFilterProp="children"
                  >
                    <Option key="1" value="Post Focus 1">
                      Post Focus 1
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="length">
                  <label className="text-secondary">Length</label>
                  <Select
                    showSearch
                    allowClear
                    placeholder="Length"
                    style={{
                      height: "32px",
                      border: "1px solid #d9d9d9",
                      borderRadius: "6px",
                      width: "100%",
                    }}
                    bordered={false}
                    dropdownStyle={{ borderRadius: "6px" }}
                    optionFilterProp="children"
                  >
                    <Option key="1" value="length">
                      Length
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Panel>
      </Collapse>

      {/* NOTE Approval */}
      <div className="d-none">
        <div className="d-flex justify-content-start align-items-center mb-4">
          <div className="font-weight-semibold me-2">Approval</div>
          <div className="h-line"></div>
        </div>
        <Row>
          <Col span={12}>
            <Form.Item name="approvalType">
              <label>Approval Type</label>
              <Select
                showSearch
                allowClear
                placeholder="Approval Type"
                style={{
                  height: "32px",
                  border: "1px solid #d9d9d9",
                  borderRadius: "6px",
                  width: "100%",
                }}
                bordered={false}
                dropdownStyle={{ borderRadius: "6px" }}
                optionFilterProp="children"
              >
                <Option key="1" value="Approval Type">
                  Approval Type
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="approvalNote">
          <label>Approval Note</label>
          <TextArea
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: "6px",
              width: "100%",
            }}
            placeholder="Approval Note"
            bordered={false}
            autoSize={{ minRows: 2, maxRows: 4 }}
          />
        </Form.Item>
      </div>
    </React.Fragment>
  )
}

export default PostForm
