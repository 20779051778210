import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link , useHistory} from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import useWebSocket, { ReadyState } from "react-use-websocket"
import moment from "moment"
import toast, { Toaster } from "react-hot-toast"
import { Dropdown as AntDropdown, Menu, Switch } from "antd"
//Import images
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"
import { useMutation, useQuery } from "@apollo/client"
import { NOTIFICATIONS, SETTINGS } from "../../../graphql/queries"
import {
  CLEAR_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS,
  READ_NOTIFICATION,
  UPDATE_SETTINGS,
} from "../../../graphql/mutation"

import notificationSnd from "./notification.mp3"

const audio = new Audio(notificationSnd)
audio.loop = false

const NotificationDropdown = React.memo(props => {
  let user = JSON.parse(localStorage.getItem("concizeUser"))
  let socketUrl = `wss://abcm1nw004.execute-api.ap-south-1.amazonaws.com/dev?userID=${user?.id}`
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl)
  const [menu, setMenu] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [count, setCount] = useState(0)
  const params = new URL(window.location.href).searchParams
  const [visible, setVisible] = useState(false)
  const history = useHistory()

  const [settings, setSettings] = useState({
    notificationSound: true,
    notificationPopup: true,
  })

  useEffect(() => {
    console.log("params", params)
    if (params && params.has("taskID")) {
      notifications.some(function (n, index) {
        if (n.type.taskID === params.get("taskID")) {
          // readNotification({
          //   variables: {
          //     id: n.id,
          //   },
          // })
          return true
        }
      })
    }
  }, [])

  const [readNotification] = useMutation(READ_NOTIFICATION, {
    onCompleted: data => {
      if (data && data.readNotification) {
        let index = notifications.findIndex(notification => {
          return notification.id === data.readNotification.id
        })
        let newNotifications = [
          ...notifications.slice(0, index),
          { ...data.readNotification },
          ...notifications.slice(index + 1),
        ]
        // setNotifications(newNotifications)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [updateSettings] = useMutation(UPDATE_SETTINGS, {
    onCompleted: data => {
      console.log("data", data)
      if (data && data.updateSettings) {
        setSettings(data.updateSettings)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const {} = useQuery(SETTINGS, {
    onCompleted: data => {
      console.log("data", data)
      if (data && data.settings) {
        setSettings(data.settings)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const {} = useQuery(NOTIFICATIONS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getAllNotifications) {
        if (notifications.length) {
          setNotifications([...data.getAllNotifications, ...notifications])
        } else {
          setNotifications(data.getAllNotifications)
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [clearAllNotifications] = useMutation(CLEAR_ALL_NOTIFICATIONS, {
    onCompleted: data => {
      console.log("data", data)
      if (data && data.clearAllNotifications) {
        setNotifications([])
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [readAllNotifications] = useMutation(READ_ALL_NOTIFICATIONS, {
    onCompleted: data => {
      if (data && data.readAllNotifications) {
        let newNotifications = []
        notifications.forEach(ele => {
          let newEle = { ...ele }
          newEle.isRead = true
          newNotifications.push(newEle)
        })
        setNotifications(newNotifications)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  useEffect(() => {
    if (notifications && notifications.length) {
      let c = 0
      notifications.forEach(n => {
        if (!n.isRead) {
          c++
        }
      })
      setCount(c)
    } else {
      setCount(null)
    }
  }, [notifications])

  useEffect(() => {
    console.log("lastMessage", lastMessage)
    if (lastMessage) {
      let notification = JSON.parse(lastMessage.data)
      if (notifications.length) {
        setNotifications([notification, ...notifications])
      } else {
        setNotifications([notification])
      }
      let msg = displayNotificationMsg(notification)
      if (settings.notificationPopup) {
        toast(
          t => {
            return (
              <Link
                to={`/${notification.type.workspaceID}/space-list/${notification.type.spaceID}?taskID=${notification.type.taskID}`}
              >
                <div className="media d-flex justify-content-center align-items-center">
                  <div
                    className="avatar-xs me-2 "
                    style={{
                      minWidth: "32px",
                      minWidth: "32px",
                    }}
                  >
                    <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-18">
                      P
                    </span>
                  </div>
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">
                      <span className="me-1">{notification.from.fName}</span>
                      <span
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {msg}
                      </span>
                    </h6>
                  </div>
                </div>
              </Link>
            )
          },
          {
            position: "top-center",
            reverseOrder: false,
            duration: 2000,
            style: {
              marginTop: "0rem",
            },
          }
        )
      }
      if (settings.notificationSound) {
        audio.play()
      }
    }
  }, [lastMessage])

  const settingsMenu = (
    <Menu
      style={{
        fontFamily: "sans-serif",
      }}
      onClick={e => {
        handleSettingsMenu(e.key)
      }}
    >
      <Menu.Item
        disabled={notifications.length === 0}
        className="mx-2 mt-1"
        key="0"
      >
        <i className="fal fa-book-reader me-1"></i> Mark All As Read
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        disabled={notifications.length === 0}
        className="mx-2 mt-1 text-danger"
        key="3"
      >
        <i className="fal fa-times me-1"></i> Clear All
      </Menu.Item>
      <Menu.ItemGroup title="Notification settings">
        <Menu.Item key="4" className="mt-1">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <i className="fal fa-volume me-1"></i> Sound
            </div>
            <Switch
              onChange={() => {
                updateSettings({
                  variables: {
                    id: settings.id,
                    input: {
                      notificationSound: !settings.notificationSound,
                      notificationPopup: settings.notificationPopup,
                    },
                  },
                })
              }}
              size="small"
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={settings.notificationSound}
            />
          </div>
        </Menu.Item>
        <Menu.Item key="5" className="mt-1">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <i className="fal fa-bell me-1"></i> Popup
            </div>
            <Switch
              onChange={() => {
                updateSettings({
                  variables: {
                    id: settings.id,
                    input: {
                      notificationSound: settings.notificationSound,
                      notificationPopup: !settings.notificationPopup,
                    },
                  },
                })
              }}
              size="small"
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={settings.notificationPopup}
            />
          </div>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )
  const handleVisibleChange = flag => {
    setVisible(flag)
  }

  const handleSettingsMenu = key => {
    switch (key) {
      case "0":
        console.log("read")
        readAllNotifications()
        break
      case "3":
        clearAllNotifications()
        break
      default:
        break
    }
  }

  const displayNotificationMsg = n => {
    let msg = ""
    switch (n.type.id) {
      case 1:
        msg = `Assigned "${n.type.title}" task to you.`
        break
      case 2:
        msg = `Unassigned "${n.type.title}" task from you.`
        break
      case 3:
        msg = `Mentioned you in "${n.type.title}" task.`
        break
      default:
        msg = "New notification"
        break
    }

    return msg
  }

  const getLink = n => {
    let link = ""

    switch (n.type.id) {
      case 1:
      case 2:
        link = `/${n.type.workspaceID}/space-list/${n.type.spaceID}?taskID=${n.type.taskID}`
        break
      case 3:
        link = `/${n.type.workspaceID}/space-list/${n.type.spaceID}?taskID=${n.type.taskID}#${n.type.commentID}`
        break
      default:
        link = "/#"
        break
    }

    return link
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => {
          if (!visible) {
            setMenu(!menu)
          }
        }}
        autoClose="outside"
        // toggle={() => {}}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell" />
          <span className="badge bg-danger rounded-pill">
            {count && count > 0 ? count : null}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <h6 className="m-0"> {props.t("Notifications")} </h6>
              <div className="cursor-pointer">
                <AntDropdown
                  arrow={{ pointAtCenter: true }}
                  overlay={settingsMenu}
                  placement="bottomRight"
                  trigger={["click"]}
                  onVisibleChange={handleVisibleChange}
                  visible={visible}
                >
                  <i
                    className="fas fa-cog font-size-14 subtask-hover p-1 rounded-3"
                    onClick={e => e.preventDefault()}
                  ></i>
                </AntDropdown>
              </div>
            </div>
            <Row className="align-items-center d-none">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <button
                disabled={notifications.length === 0}
                className="col-auto btn link-primary"
                onClick={readAllNotifications}
              >
                <div className="small">Mark All As Read</div>
              </button>
              |
              <button
                disabled={notifications.length === 0}
                className="col-auto btn link-primary"
                onClick={clearAllNotifications}
              >
                <div className="small">Clear All</div>
              </button>
            </Row>
          </div>
          {/* <Toaster /> */}

          <SimpleBar style={{ height: "300px" }}>
            {notifications && notifications.length
              ? notifications.map(notification => {
                  let link = getLink(notification)
                  console.log("link", link)
                  return (
                    <Link
                      to={link}
                      className="text-reset notification-item"
                      onClick={() => {
                        history.push(link)
                        if (!params.has("taskID")) {
                          return readNotification({
                            variables: {
                              id: notification.id,
                            },
                          })
                        }
                        return
                      }}
                    >
                      <div
                        className="media"
                        style={{
                          borderBottom: "1px solid rgba(100, 100, 100, 0.1)",
                          background: notification.isRead ? "" : "#ecf8fe",
                        }}
                      >
                        <div
                          className="avatar-xs me-2"
                          style={{
                            minWidth: "32px",
                            minWidth: "32px",
                          }}
                        >
                          <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-18">
                            {notification.from.fName.charAt(0)}
                          </span>
                        </div>
                        <div className="media-body">
                          <h6 className="mt-0 mb-1">
                            <span className="me-1">
                              {notification.from.fName}
                            </span>
                            <span
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              {displayNotificationMsg(notification)}
                            </span>
                          </h6>
                          <div className="font-size-12 text-muted">
                            <div className="mb-0 d-flex align-items-center">
                              <div>
                                {moment.utc(notification.timeStamp).fromNow()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })
              : null}

            <Link to="" className="text-reset notification-item d-none">
              <div className="media">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="bx bx-cart" />
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your order is placed")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("If several languages coalesce the grammar")}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      {props.t("3 min ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item d-none">
              <div className="media">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="bx bx-badge-check" />
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your item is shipped")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("If several languages coalesce the grammar")}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      {props.t("3 min ago")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="" className="text-reset notification-item d-none">
              <div className="media">
                <img
                  src={avatar4}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="media-body">
                  <h6 className="mt-0 mb-1">Salena Layfield</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "As a skeptical Cambridge friend of mine occidental"
                      ) + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </SimpleBar>
          <div className="p-2 border-top d-grid d-none">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
})

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
