import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Card, CardBody, Col, Container, Row, Spinner, Badge } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import AccountModal from "./components/accountModal"
import "./accounts.css"
import user1 from "../../assets/images/users/user.png"

import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { ACCOUNTS } from "../../graphql/queries"
import {
  ADD_LINKEDIN,
  ADD_TWITTER,
  DELETE_ACCOUNT,
} from "../../graphql/mutation"
import ConfirmDeleteModal from "components/Dialog/confirmDeleteModal"

const Accounts = () => {
  const [accounts, setAccounts] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [accountData, setAccountData] = useState(undefined)

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const {} = useQuery(ACCOUNTS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.accounts) {
        setAccounts(data.accounts)
      }
      let urlParameters = new URLSearchParams(window.location.search)
      if (urlParameters && urlParameters.get("code")) {
        handleAddLinkedIn(urlParameters)
      } else if (urlParameters && urlParameters.get("oauth_token")) {
        handleAddTwitter(urlParameters)
      }
    },
    onError: error => {
      console.log("error", error)
    },
  })

  const [addLinkedin] = useMutation(ADD_LINKEDIN, {
    onCompleted: data => {
      setLoading(false)
      if (data && data.addLinkedin) {
        let newAccount = data.addLinkedin
        if (accounts && accounts.length) {
          setAccounts([newAccount, ...accounts])
        } else {
          setAccounts([newAccount])
        }
      }
    },
    onError: error => {
      setLoading(false)
      console.log("error", error)
    },
  })
  const [addTwitter] = useMutation(ADD_TWITTER, {
    onCompleted: data => {
      setLoading(false)
      if (data && data.addTwitter) {
        let newAccount = data.addTwitter
        if (accounts && accounts.length) {
          setAccounts([newAccount, ...accounts])
        } else {
          setAccounts([newAccount])
        }
      }
    },
    onError: error => {
      setLoading(false)
      console.log("error", error)
    },
  })

  const [deleteAccount] = useMutation(DELETE_ACCOUNT, {
    onCompleted: data => {
      if (data && data.deleteAccount) {
        let index = accounts.findIndex(acc => acc.id === accountData.id)
        if (index > -1) {
          setAccounts([
            ...accounts.slice(0, index),
            ...accounts.slice(index + 1),
          ])
        }
      }
      setAccountData(undefined)
      setShowDeleteModal(false)
    },
    onError: err => {
      setAccountData(undefined)
      setShowDeleteModal(false)
      console.log("err", err)
    },
  })

  const handleAddLinkedIn = urlParameters => {
    setLoading(true)
    let request = {
      grantType: "authorization_code",
      code: urlParameters.get("code"),
      redirectUri: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
      clientID: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      clientSecret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
      accountType: "LinkedIn",
      isActive: true,
    }

    addLinkedin({
      variables: {
        input: request,
      },
    })
  }

  const handleAddTwitter = urlParameters => {
    setLoading(true)
    let request = {
      oAuthToken: urlParameters.get("oauth_token"),
      oAuthVerifier: urlParameters.get("oauth_verifier"),
    }

    addTwitter({
      variables: {
        input: request,
      },
    })
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleCreateAccount = () => {
    setShowModal(true)
  }

  const handleDeleteAccount = acc => {
    console.log("acc", acc)
    setShowDeleteModal(true)
    setAccountData(acc)
  }

  const { SearchBar } = Search

  const pageOptions = {
    sizePerPage: 5,
    totalSize: accounts.length,
    custom: true,
  }

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
    style: { backgroundColor: "#fafafa" },
  }

  const accountsColumn = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
    },
    {
      text: "Account Name",
      dataField: "firstName",
      sort: true,
      formatter: (_, account) => {
        return (
          <div className="d-flex justify-content-start align-items-center">
            <img
              className="rounded-circle header-profile-user me-1"
              src={account.profilePicURL ? account.profilePicURL : user1}
              alt="Header Avatar"
            />
            <span>
              {account.firstName} {account.lastName}
            </span>
          </div>
        )
      },
    },
    {
      text: "Account Type",
      dataField: "accountType",
      sort: true,
      formatter: (_, account) => {
        let src = "https://img.icons8.com/color/48/000000/linkedin.png"
        switch (account.accountType) {
          case "LinkedIn":
            src = "https://img.icons8.com/color/48/000000/linkedin.png"
            break
          case "Twitter":
            src = "https://img.icons8.com/color/48/000000/twitter.png"
            break
          default:
            break
        }
        return (
          <div>
            <img className="me-1" width="24" src={src} />
            <span>{account.accountType}</span>
          </div>
        )
      },
    },
    {
      text: "Status",
      dataField: "isActive",
      sort: true,
      formatter: (_, account) => {
        let badge
        if (account.isActive) {
          badge = (
            <Badge pill className="badge-soft-success me-1 font-size-12">
              Active
            </Badge>
          )
        } else {
          badge = (
            <Badge pill className="badge-soft-secondary me-1 font-size-12">
              Inactive
            </Badge>
          )
        }
        return badge
      },
    },
    {
      text: "Owner",
      dataField: "owner",
      sort: true,
      formatter: (_, account) => {
        return (
          <div className="d-flex justify-content-start align-items-center">
            <div className="avatar-xs me-1">
              <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-14">
                {account?.user?.fName !== undefined
                  ? account?.user?.fName.charAt(0)
                  : "?"}
              </span>
            </div>
            <span>
              <div>
                {account.user.fName} {account.user.lName}
              </div>
            </span>
          </div>
        )
      },
    },
    {
      text: "Following",
      dataField: "following",
      sort: true,
    },
    {
      text: "Posts",
      dataField: "posts",
      sort: true,
    },
    {
      text: "Groups",
      dataField: "groups",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (_, account) => (
        <div className="d-flex gap-3">
          <div
            className="text-success d-none"
            onClick={() => {}}
            style={{ cursor: "pointer" }}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
          </div>
          <div
            className="text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleDeleteAccount(account)
            }}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
          </div>
        </div>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Accounts | Concize</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            left="Accounts"
            url="/settings"
            urlTitle="Settings"
            right="Accounts"
          />

          <Row>
            <Col lg="12">
              <Card style={{ position: "relative" }}>
                <CardBody>
                  <Card
                    style={{
                      position: "absolute",
                      zIndex: "2",
                      left: `calc(50% - 100px)`,
                      top: "30%",
                      width: "150px",
                    }}
                    className="shadow-lg text-center"
                  >
                    <CardBody hidden={!loading}>
                      <p>Please wait</p>
                      <Spinner color="primary" hidden={!loading} />
                    </CardBody>
                  </Card>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={accounts}
                    columns={accountsColumn}
                    keyField="name"
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="name"
                        data={accounts}
                        columns={accountsColumn}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end d-flex justify-content-end">
                                  <button
                                    className="font-16 btn-block btn btn-primary rounded-3 me-2"
                                    onClick={handleCreateAccount}
                                  >
                                    <i className="fas fa-plus-circle me-2" />
                                    Create Account
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    keyField="name"
                                    // selectRow={selectRow}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                    responsive
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="d-none">
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <AccountModal
          showModal={showModal}
          editMode={editMode}
          handleClose={handleCloseModal}
        />
        <ConfirmDeleteModal
          showDialog={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          onConfirm={() => {
            deleteAccount({
              variables: {
                accountID: accountData.id,
              },
            })
          }}
          title="Delete Account"
          subText={`Are you sure you want to delete "${accountData?.firstName} ${accountData?.accountType}" account?`}
        />
      </div>
    </React.Fragment>
  )
}

export default Accounts
