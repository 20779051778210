import React, { useState, useContext } from "react"
import { Card, CardBody, Button, Col, Spinner, Badge } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import avatar from "../../../assets/images/users/user.png"
import toast, { Toaster } from "react-hot-toast"
import { useMutation, useQuery } from "@apollo/client"
import { USER } from "../../../graphql/queries"
import {
  REMOVE_PROFILE_PICTURE,
  UPDATE_USER_PROFILE,
  UPLOAD_PROFILE_PICTURE,
} from "../../../graphql/mutation"
import { Spin, Tooltip } from "antd"
import { CalendarContext } from "contexts/CalendarContext"

const UserInfo = () => {
  const [user, setUser] = useState(undefined)
  const [profilePicSrc, setProfilePicSrc] = useState(null)
  const [showSpinner, setShowSpinner] = useState(true)

  const region = process.env.REACT_APP_AWS_S3_REGION
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY
  const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY
  const bucketName = process.env.REACT_APP_AWS_S3_BUCKET_NAME

  const { profilePicSrcCtx, updateProfilePicCtx } = useContext(CalendarContext)

  const s3 = new AWS.S3({
    region,
    accessKeyId,
    secretAccessKey,
  })

  const {} = useQuery(USER, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data) {
        setUser(data.user)
        if (data.user.profileURL) {
          setProfilePicSrc(data.user.profileURL)
          updateProfilePicCtx(data.user.profileURL)
        }
        setShowSpinner(false)
      }
    },
    onError: err => {
      console.log("error", err)
      setShowSpinner(false)
    },
  })

  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: data => {
      if (data && data.updateUserProfile) {
        setUser(data.updateUserProfile)
      }
      toast.success("Successfully updated your profile", {
        position: "top-right",
      })
      setShowSpinner(false)
    },
    onError: err => {
      console.log("error", err)
      setShowSpinner(false)
    },
  })

  const handleUpdateProfile = v => {
    let updatedUserInfo = {
      fName: v.fName,
      lName: v.lName,
      email: user.email,
      mobile: v.mobile,
    }
    setShowSpinner(true)
    updateUserProfile({
      variables: {
        input: updatedUserInfo,
      },
    })
  }

  const [updateProfilePicture] = useMutation(UPLOAD_PROFILE_PICTURE, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      console.log("data", data)
      if (data && data.updateProfilePicture) {
        setProfilePicSrc(data.updateProfilePicture)
        updateProfilePicCtx(data.updateProfilePicture)
        toast.success("Successfully updated your profile picture", {
          position: "top-right",
        })
      }
      setShowSpinner(false)
    },
    onError: err => {
      console.log("err", err)
      setShowSpinner(false)
    },
  })

  const [removeProfilePicture] = useMutation(REMOVE_PROFILE_PICTURE, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.removeProfilePicture) {
        setProfilePicSrc(null)
        updateProfilePicCtx(null)
        toast.success("Successfully removed your profile picture", {
          position: "top-right",
        })
      }
      setShowSpinner(false)
    },
    onError: err => {
      console.log("err", err)
      setShowSpinner(false)
    },
  })

  const handleUpload = event => {
    let file = event.target.files[0]
    if (file === null || file === undefined) return
    const params = {
      Bucket: "concize-local-medias",
      Key: user.id,
      Body: file,
    }
    setShowSpinner(true)
    let promise = s3.upload(params).promise()
    Promise.all([promise]).then(datas => {
      if (datas && datas.length) {
        let imageData = datas[0]
        setProfilePicSrc(null)
        updateProfilePicCtx(null)
        updateProfilePicture({
          variables: {
            url: imageData.Location,
          },
        })
      } else {
        setShowSpinner(false)
      }
    })
  }

  const handleRemoveProfilePic = () => {
    setShowSpinner(true)
    removeProfilePicture()
  }

  return (
    <Col className="position-relative" span={8}>
      <Card className="shadow">
        <Spin spinning={showSpinner}>
          <CardBody>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                src={profilePicSrc ? profilePicSrc : avatar}
                alt=""
                key={profilePicSrc ? profilePicSrc : avatar}
                className="avatar-lg rounded-circle img-thumbnail"
              />
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
                onChange={handleUpload}
              />
              <div className="d-flex justify-content-center align-items-center">
                <label htmlFor="contained-button-file">
                  <div className="btn btn-primary btn-sm rounded-2 mt-2">
                    Upload
                  </div>
                </label>
                {profilePicSrc ? (
                  <Tooltip title="Remove profile picture">
                    <div
                      className="subtask-hover rounded-3 px-2 py-1  cursor-pointer ms-1"
                      onClick={handleRemoveProfilePic}
                    >
                      <i className="fal fa-times font-size-14"></i>
                    </div>
                  </Tooltip>
                ) : null}
              </div>

              <h4 className="my-2">
                {user ? user.fName + " " + user.lName : ""}
              </h4>
              <p>Email: {user ? user.email : ""}</p>
              <p>
                Organization:
                <span className="font-weight-semibold ms-1">
                  {user ? user.tenant.Name : ""}
                </span>
              </p>
              <p>
                Role:
                <Badge
                  pill
                  className="badge-soft-primary ms-1 mb-2 p-1 font-size-12"
                >
                  {user ? user.role.replace(/_/g, " ") : ""}
                </Badge>
              </p>
              <button type="button" className="btn btn-light btn-sm d-none">
                <i className="fal fa-envelope align-middle me-2"></i>
                Message
              </button>
            </div>
            <hr />
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleUpdateProfile(v)
              }}
            >
              <div className="form-group">
                <AvField
                  name="fName"
                  label="First Name"
                  value={user ? user.fName : ""}
                  className="form-control mb-2"
                  placeholder="Enter first name"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                />
                <AvField
                  name="lName"
                  label="Last Name"
                  value={user ? user.lName : ""}
                  className="form-control mb-2"
                  placeholder="Enter last name"
                  type="text"
                />
                <AvField
                  name="mobile"
                  label="Mobile"
                  value={user ? user.mobile : ""}
                  className="form-control"
                  placeholder="Enter mobile number"
                  type="text"
                />
                <div className="text-center mt-4">
                  <Button type="submit" color="primary">
                    Update Profile
                  </Button>
                </div>
              </div>
            </AvForm>
          </CardBody>
        </Spin>
      </Card>
      <Toaster />
    </Col>
  )
}

export default UserInfo
