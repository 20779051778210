import React, { useState, useEffect } from "react"
import { Input } from "antd"
const { TextArea } = Input

const DrawerDescriptionField = ({ data, handleUpdateTask }) => {
  const [description, setDescription] = useState(undefined)

  useEffect(() => {
    if (data && data.description) {
      setDescription(data.description)
    } else {
      setDescription("")
    }
  }, [data])

  const handleDescriptionChange = event => {
    setDescription(event.target.value)
  }

  const handleUpdateDescription = () => {
    if (data && data.description !== description) {
      let updatedTask = { ...data }
      updatedTask["description"] = description

      const auditInput = {
        auditType: 6,
        from: data.description,
        to: description,
      }

      handleUpdateTask(updatedTask, auditInput)
    }
  }

  return (
    <TextArea
      autoSize={{ minRows: 3, maxRows: 5 }}
      className="textareadHover"
      value={description ? description : ""}
      placeholder="Add more detail to this task..."
      onChange={handleDescriptionChange}
      onBlur={handleUpdateDescription}
      onMouseLeave={handleUpdateDescription}
    />
  )
}

export default DrawerDescriptionField
