import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import { setContext } from "@apollo/client/link/context"
import store from "./store"

/* import { WebSocketLink } from "@apollo/client/link/ws"
import { getMainDefinition } from "@apollo/client/utilities"

const wsLink = new WebSocketLink({
  uri: "ws://localhost:8080/query",
  options: {
    reconnect: true,
    lazy: true,
  },
})
*/

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const obj = JSON.parse(localStorage.getItem("authUser"))
  const token = obj?.stsTokenManager?.accessToken
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

/* const mainLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  wsLink,
  authLink.concat(httpLink)
) */

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // link: mainLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getTask: {
            read: (_, { args, toReference }) => {
              return toReference({
                __typename: "Task",
                id: args.id,
              })
            },
          },
          getSubTask: {
            read: (_, { args, toReference }) => {
              return toReference({
                __typename: "SubTask",
                id: args.id,
              })
            },
          },
        },
      },
    },
  }),
})

const app = (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
