import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Form, DatePicker } from "antd"
import PostPreview from "./postPreview"
import PostForm from "./postForm"
import DeletePostModal from "./deletePostModal"
import moment from "moment"
import AWS from "aws-sdk"
import uuid from "react-uuid"
import { VideocamSharp } from "@material-ui/icons"

const PostModal = ({
  socialAccounts,
  showPostModal,
  data,
  editMode,
  handleClosePostModal,
  selectedDay,
  onSave,
  onUpdate,
  onDelete,
  handleLoadingState,
  campaigns,
}) => {
  const region = process.env.REACT_APP_AWS_S3_REGION
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY
  const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY
  const bucketName = process.env.REACT_APP_AWS_S3_BUCKET_NAME

  const s3 = new AWS.S3({
    region,
    accessKeyId,
    secretAccessKey,
  })

  function keyGen(keyLength) {
    var i,
      key = "",
      characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"

    var charactersLength = characters.length

    for (i = 0; i < keyLength; i++) {
      key += characters.substr(
        Math.floor(Math.random() * charactersLength + 1),
        1
      )
    }

    return key
  }

  const [selectedAccounts, setSelectedAccounts] = useState(undefined)
  const [content, setContent] = useState(undefined)
  const [contentStr, setContentStr] = useState(undefined)
  const [scheduleDate, setScheduleDate] = useState(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [photos, setPhotos] = useState(undefined)
  const [videos, setVideos] = useState(undefined)
  const [files, setFiles] = useState(undefined)
  const [isMobileView, setIsMobileView] = useState(true)

  const handleAddPhotos = (mediaFiles, mediaType) => {
    if (mediaType === 1) {
      setPhotos(mediaFiles)
    } else {
      setVideos(mediaFiles)
    }
    setFiles(mediaFiles)
  }

  useEffect(() => {
    if (showPostModal) {
      if (editMode) {
        setSelectedAccounts(data.accountIDs)
        setContent(data.content)

        if (data.scheduleDate) {
          setScheduleDate(moment(data.scheduleDate))
        } else {
          setScheduleDate(undefined)
        }
      } else {
        setScheduleDate(moment())
        setSelectedAccounts(undefined)
        setContent(undefined)
      }
      setShowDeleteModal(false)
    }
  }, [showPostModal])

  useEffect(() => {
    if (selectedDay && !editMode) {
      setScheduleDate(moment(selectedDay.date))
    }
  }, [selectedDay, showPostModal])

  const handleSelectAccounts = event => {
    setSelectedAccounts(event)
  }

  const handleContentChange = (cotentHtml, contentTxt) => {
    setContent(cotentHtml)
    setContentStr(contentTxt)
  }

  const onSubmit = values => {
    handleLoadingState(true)
    if (editMode) {
      let postInput = {
        campaignID: values.campaign,
        start: data.start,
        accountIDs: values.accounts,
        subject: "Publish",
        content: contentStr,
        isPicturePost: photos && photos.length ? true : false,
        isVideoPost: videos && videos.length ? true : false,
        isTextPost:
          (photos && photos.length) || (videos && videos.length) ? false : true,
      }

      if (scheduleDate) {
        postInput = {
          ...postInput,
          isPosted: false,
          scheduleDate: scheduleDate.toDate(),
        }
        onUpdate(postInput, data.id, "schedule_post")
      } else {
        onUpdate(postInput, data.id, "direct_post")
      }
      handleClosePostModal()
    } else {
      let postInput = {
        campaignID: values.campaign,
        start: selectedDay.dateStr,
        accountIDs: values.accounts,
        subject: "Publish",
        content: contentStr,
        isPicturePost: photos && photos.length ? true : false,
        isVideoPost: videos && videos.length ? true : false,
        isTextPost:
          (photos && photos.length) || (videos && videos.length) ? false : true,
      }
      if (files && files.length) {
        // Upload to S3
        console.log("files", files)
        let awsMedias = []
        let promises = files.map(singleFile => {
          let key = keyGen(10) + singleFile.path
          const params = {
            Bucket: "concize-local-medias",
            Key: key,
            Body: singleFile,
          }
          return s3.upload(params).promise()
        })
        Promise.all(promises).then(datas => {
          if (datas && datas.length === files.length) {
            datas.forEach((ele, index) => {
              let singleFile = files[index]
              awsMedias.push({
                id: uuid(),
                key: ele.key,
                name: singleFile.path,
                type: singleFile.type,
                preview: ele.Location,
              })
            })
            postInput["awsMedias"] = awsMedias
            if (scheduleDate) {
              postInput = {
                ...postInput,
                isPosted: false,
                scheduleDate: scheduleDate.toDate(),
              }
              onSave(postInput, "schedule_post")
            } else {
              console.log("postInput", postInput)
              onSave(postInput, "direct_post")
            }
          }
        })
      } else {
        if (scheduleDate) {
          postInput = {
            ...postInput,
            isPosted: false,
            scheduleDate: scheduleDate.toDate(),
          }
          onSave(postInput, "schedule_post")
        } else {
          onSave(postInput, "direct_post")
        }
      }
    }
    handleClosePostModal()
  }

  const handleDeletePost = () => {
    if (data) {
      setShowDeleteModal(true)
    }
  }

  const handleDeleteConfirm = () => {
    onDelete(data.id)
    handleClosePostModal()
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const handleSchedulePost = date => {
    if (date) {
      setScheduleDate(date)
    } else {
      setScheduleDate(undefined)
    }
  }

  return (
    <Modal
      className="modal-fullscreen widget-modal"
      size="xl"
      isOpen={showPostModal}
      toggle={handleClosePostModal}
    >
      <ModalHeader tag="h4" toggle={handleClosePostModal}>
        {editMode ? "Edit Post" : "Create Post"}
      </ModalHeader>
      <Form
        onFinish={onSubmit}
        initialValues={{
          ["accounts"]: data ? data.accountIDs : undefined,
          ["content"]: data ? data.content : undefined,
          ["campaign"]: data ? data.campaign?.id : null,
        }}
      >
        <ModalBody className="custom-modal-body">
          <div className="form-and-preview">
            <div className="left-form">
              <PostForm
                socialAccounts={socialAccounts}
                handleSelectAccounts={handleSelectAccounts}
                handleContentChange={handleContentChange}
                selectedAccounts={selectedAccounts}
                content={content}
                handleAddPhotos={handleAddPhotos}
                editMode={editMode}
                campaigns={campaigns}
                files={data && data.awsMedias}
              />
            </div>
            <div className="right-preview post-preview">
              <PostPreview
                mobile={isMobileView}
                mediaFiles={files}
                socialAccounts={socialAccounts}
                selectedAccounts={selectedAccounts}
                content={content}
                posts={data && data.socialPosts}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <div>
                <DatePicker
                  value={scheduleDate}
                  showTime={{ format: "hh:mm A" }}
                  disabledDate={current =>
                    current.isBefore(moment().subtract(1, "day"))
                  }
                  placeholder="Schedule Post"
                  className="rounded-3"
                  format="MMM Do YYYY, hh:mm A"
                  // onOk={handleSchedulePost}
                  onOk={null}
                  onChange={handleSchedulePost}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div
                onClick={handleClosePostModal}
                className="btn btn-outline-secondary rounded-3 me-2"
              >
                Cancel
              </div>
              <div className="btn btn-outline-primary rounded-3 me-2">
                Save as Draft
              </div>
              <div
                hidden={!editMode}
                onClick={handleDeletePost}
                className="btn btn-danger rounded-3 me-2"
              >
                Delete Post
              </div>
              <button type="submit" className="btn btn-primary rounded-3">
                {editMode ? "Update post" : "Publish"}
              </button>
            </div>
          </div>
        </ModalFooter>
      </Form>
      <DeletePostModal
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleDeleteConfirm={handleDeleteConfirm}
      />
      <div className="mobile-or-pc">
        <div
          className={isMobileView ? "blue-bg me-2" : "me-2"}
          onClick={() => setIsMobileView(true)}
        >
          <i className="fal fa-mobile font-size-24"></i>
        </div>
        <div
          className={!isMobileView ? "blue-bg" : ""}
          onClick={() => setIsMobileView(false)}
        >
          <i className="fal fa-laptop font-size-20"></i>
        </div>
      </div>
    </Modal>
  )
}

export default PostModal
