import React, { useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap"
import { useLazyQuery } from "@apollo/client"
import { HANDLE_TWITTER_LOGIN } from "../../../graphql/queries"

const AccountModal = ({ showModal, editMode, handleClose }) => {
  useEffect(() => {}, [])
  const linkedinURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_URL}&state=04830o43r4fr3fd3&scope=r_basicprofile%20r_emailaddress%20w_member_social`

  const handleLinkedinLogin = () => {
    // window.open(linkedinURL, "newwindow", "width=600, height=600")
  }

  const [handleTwitterLogin] = useLazyQuery(HANDLE_TWITTER_LOGIN, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.handleTwitterLogin) {
        let oAuthData = data.handleTwitterLogin
        if (oAuthData.oAuthToken && oAuthData.oAuthTokenSecret) {
          let element = document.createElement("a")
          let url = `https://twitter.com/oauth/authorize?oauth_token=${oAuthData.oAuthToken}&oauth_token_secret=${oAuthData.oAuthTokenSecret}&oauth_callback_confirmed=true`
          element.href = url
          element.click()
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  return (
    <Modal size="lg" isOpen={showModal} backdrop="static" centered={true}>
      <ModalHeader toggle={handleClose}>
        <div>Create Account</div>
        <small className="font-size-11 text-secondary">
          Choose a social account you would like to add an account for.
        </small>
      </ModalHeader>
      <ModalBody>
        <div className="search-box chat-search-box py-4">
          <div className="position-relative">
            <Input
              type="text"
              className="form-control accounts-search"
              placeholder="Search..."
            />
            <i className="bx bx-search-alt search-icon" />
          </div>
        </div>

        <div className=" mt-4 my-2 mx-3 d-flex justify-content-start align-items-center flex-wrap">
          <a href={linkedinURL} className="account-box me-4">
            <img
              width="48"
              src="https://img.icons8.com/color/48/000000/linkedin.png"
            />
          </a>
          <div onClick={handleTwitterLogin} className="account-box">
            <img
              width="48"
              src="https://img.icons8.com/color/48/000000/twitter.png"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div
          className="btn btn-outline-secondary rounded-3"
          onClick={handleClose}
        >
          Cancel
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default AccountModal
