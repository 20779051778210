import React, { useState, useEffect, useContext } from "react"
import MetaTags from "react-meta-tags"
import "./calendar.css"
import moment from "moment"
import { Container, Card, CardBody } from "reactstrap"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import "@fullcalendar/bootstrap/main.css"
import PostModal from "./components/postModal"
import user1 from "../../assets/images/users/user.png"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import { ACCOUNTS, POSTS, WORKSPACE_CAMPAIGNS } from "../../graphql/queries"
import {
  CREATE_POST,
  UPDATE_POST,
  DELETE_POST,
  SCHEDULE_POST,
} from "../../graphql/mutation"
import toast from "react-hot-toast"
import { useParams } from "react-router"
import { Spin } from "antd"
import { CalendarContext } from "contexts/CalendarContext"

const Calendar = () => {
  const params = useParams()
  const [selectedDay, setSelectedDay] = useState(0)
  const [showPostModal, setShowPostModal] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [calendarAPI, setCalendarAPI] = useState(undefined)
  const [posts, setPosts] = useState(undefined)
  const [post, setPost] = useState(undefined)
  const [accounts, setAccounts] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [campaigns, setCampaigns] = useState(undefined)

  const { workspaceCtx } = useContext(CalendarContext)

  useEffect(() => {
    let location = window.location
    if (location.href.includes("showModal=1")) {
      setShowPostModal(true)
    }
  }, [window.location])

  const [workspaceCampaigns] = useLazyQuery(WORKSPACE_CAMPAIGNS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.workspaceCampaigns) {
        setCampaigns(data.workspaceCampaigns)
      }
    },
    onError: error => {
      console.log("error", error)
    },
  })

  useEffect(() => {
    if (params.workspaceID) {
      workspaceCampaigns({
        variables: {
          workspaceID: params.workspaceID,
        },
      })
    }
  }, [params.workspaceID])

  const {} = useQuery(ACCOUNTS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.accounts) {
        setAccounts(data.accounts)
      }
    },
    onError: error => {
      console.log("error", error)
    },
  })

  const handleLoadingState = loadingState => {
    setLoading(loadingState)
  }

  const {} = useQuery(POSTS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.posts) {
        setPosts(data.posts)
      }
      setLoading(false)
    },
    onError: error => {
      console.log("error", error)
      setLoading(false)
    },
  })

  const [schedulePost] = useMutation(SCHEDULE_POST, {
    onCompleted: data => {
      if (data && data.schedulePost) {
        if (posts && posts.length) {
          setPosts([data.schedulePost, ...posts])
        } else {
          setPosts([data.schedulePost])
        }
      }
      setLoading(false)
    },
    onError: error => {
      console.log("error", error)
      setLoading(false)
    },
  })

  const [createPost] = useMutation(CREATE_POST, {
    onCompleted: data => {
      if (data && data.createPost) {
        if (posts && posts.length) {
          setPosts([data.createPost, ...posts])
        } else {
          setPosts([data.createPost])
        }
      }
      setLoading(false)
    },
    onError: error => {
      console.log("error", error)
      setLoading(false)
    },
  })
  const [updatePost] = useMutation(UPDATE_POST, {
    onCompleted: data => {
      if (data && data.updatePost) {
        let index = posts.findIndex(post => post.id === data.updatePost.id)
        setPosts([
          ...posts.slice(0, index),
          { ...data.updatePost },
          ...posts.slice(index + 1),
        ])
      }
      setLoading(false)
    },
    onError: error => {
      console.log("error", error)
      setLoading(false)
    },
  })

  const [deletePost] = useMutation(DELETE_POST, {
    onCompleted: data => {
      if (data && data.deletePost) {
        let index = posts.findIndex(ele => {
          return ele.id === post.id
        })
        let newPosts = [...posts]
        newPosts.splice(index, 1)
        setPosts(newPosts)
        setPost(null)
        setLoading(false)
      }
    },
    onError: error => {
      console.log("error", error)
      setLoading(false)
    },
  })

  const handleDateClick = arg => {
    setPost(undefined)
    setEditMode(false)
    setCalendarAPI(arg.view.calendar)
    const date = arg["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )
    const modifiedData = { ...arg, date: modifiedDate }
    setSelectedDay(modifiedData)
  }

  const handleClosePostModal = () => {
    setSelectedDay(undefined)
    setShowPostModal(false)
    setEditMode(false)
  }

  const handlePostSave = (postInput, postType) => {
    setLoading(true)
    if (postType === "direct_post") {
      createPost({
        variables: {
          input: postInput,
        },
      })
    } else {
      schedulePost({
        variables: {
          input: postInput,
        },
      })
    }
  }
  const handlePostUpdate = (postInput, id, postType) => {
    setLoading(true)
    if (postType === "direct_post" || "schedule_post") {
      updatePost({
        variables: {
          id,
          input: postInput,
        },
      })
    }

    /* let index = posts.findIndex(post => {
      return post.id === id
    })
    setPosts([
      ...posts.slice(0, index),
      { ...postInput },
      ...posts.slice(index + 1),
    ]) */
  }

  const handlePostDelete = id => {
    console.log(id, post)
    if (id && id !== "") {
      setLoading(true)
      deletePost({
        variables: {
          id,
        },
      })
    }
  }

  const handleEventClick = arg => {
    const postEvent = arg.event

    let accountIDs = []
    postEvent.extendedProps.accounts.forEach(ele => accountIDs.push(ele.id))
    setPost({
      id: postEvent.id,
      content: postEvent.extendedProps.content,
      accountIDs,
      socialPosts: postEvent.extendedProps.socialPosts,
      start: postEvent.start,
      scheduleDate: postEvent.extendedProps.scheduleDate,
      awsMedias: postEvent.extendedProps.awsMedias,
      campaign: postEvent.extendedProps.campaign,
    })
    setEditMode(true)
    setShowPostModal(true)
  }

  const renderEventContent = eventInfo => {
    let postEvent = eventInfo.event

    let postProps = postEvent.extendedProps

    let src = []
    postProps.accounts.forEach(ele => {
      if (ele.accountType === "LinkedIn") {
        src.push("https://img.icons8.com/color/48/000000/linkedin.png")
      } else {
        src.push("https://img.icons8.com/color/48/000000/twitter.png")
      }
    })

    return (
      <div className="single-event d-flex flex-column justify-content-start">
        <div className="event-box d-flex justify-content-start align-items-start">
          <div className="position-relative">
            <img
              className="position-relative rounded-circle cal-event-dp header-profile-user me-1"
              src={user1}
              alt="Header Avatar"
            />
            <span
              className={
                postProps.isPosted
                  ? "position-absolute top-0 start-0 bg-success"
                  : "position-absolute top-0 start-0 bg-warning"
              }
              style={{
                width: "6px",
                height: "6px",
                borderRadius: "3px",
              }}
            ></span>
          </div>
          <div
            style={{
              width: "100%",
              overflow: "hidden",
            }}
          >
            <small className="text-info">
              {moment(postProps.createdAt).local().format("hh.mm  a")}
            </small>
            <div
              className="font-weight-semibold"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {postProps.content}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              right: "5px",
            }}
          >
            {src.map(val => {
              return <img width="12" src={val} />
            })}
          </div>
        </div>
        <div className="ms-2 py-1">
          <i className="bx bx-message-rounded-edit me-1"></i>
          Message
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          overflow: loading ? "fixed" : "auto",
        }}
      >
        <MetaTags>
          <title>Concize - Workspace</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div>
              <h4 className="mb-sm-0 font-size-18">Calendar</h4>
            </div>
          </div>

          <Card>
            <CardBody>
              <Spin spinning={loading} tip="Loading...">
                <FullCalendar
                  plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                  slotDuration={"00:15:00"}
                  handleWindowResize={true}
                  themeSystem="bootstrap"
                  validRange={
                    {
                      // start: "2022-03-21",
                    }
                  }
                  headerToolbar={{
                    left: "today dayGridMonth,dayGridWeek,dayGridDay",
                    center: "title",
                    right: "prevYear,prev,next,nextYear",
                  }}
                  selectAllow={info => {
                    if (info && info.start < moment().startOf("day")) {
                      setShowPostModal(false)
                      toast.error(
                        "Please select today's date or future dates",
                        {
                          duration: 3000,
                          position: "top-right",
                        }
                      )
                      return false
                    }
                    setShowPostModal(true)
                    return true
                  }}
                  events={posts}
                  editable={true}
                  droppable={true}
                  selectable={true}
                  dateClick={handleDateClick}
                  eventContent={renderEventContent}
                  eventClick={handleEventClick}
                  // drop={onDrop}
                />
              </Spin>
            </CardBody>
          </Card>
        </Container>
        <PostModal
          handleLoadingState={handleLoadingState}
          socialAccounts={accounts}
          data={post}
          showPostModal={showPostModal}
          editMode={editMode}
          handleClosePostModal={handleClosePostModal}
          selectedDay={selectedDay}
          campaigns={campaigns}
          onSave={handlePostSave}
          onUpdate={handlePostUpdate}
          onDelete={handlePostDelete}
        />
      </div>
    </React.Fragment>
  )
}

export default Calendar
