import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import {
  Row,
  Col,
  Form,
  Typography,
  Input,
  Select,
  Divider,
  Switch,
  Collapse,
} from "antd"

import { ROLE } from "../../../components/helpers/enums"
import { WORKSPACES, ALLOCATION_PROFILES } from "../../../graphql/queries"
import { useLazyQuery } from "@apollo/client"

const { Text } = Typography
const { Panel } = Collapse

const UserModal = ({
  showModal,
  handleClose,
  data,
  customRoles,
  onUpdate,
  onSave,
  editMode,
}) => {
  const [workspaces, setWorkspaces] = useState(undefined)
  const [allocationProfiles, setAllocationProfiles] = useState(undefined)
  const [showWorksapce, setShowWorkspace] = useState(false)
  const [showAllocation, setShowAllocation] = useState(false)

  useEffect(() => {
    if (editMode) {
      setShowWorkspace(data.role === ROLE.spaceAdmin ? true : false)
      setShowAllocation(data.role === ROLE.spaceUser ? true : false)
      getWorkspaces()
      getAllocationProfiles()
    } else {
      setShowWorkspace(false)
      setShowAllocation(false)
    }
  }, [showModal])

  const [getWorkspaces] = useLazyQuery(WORKSPACES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data) {
        setWorkspaces(data.workspaces)
      }
    },
  })

  const [getAllocationProfiles] = useLazyQuery(ALLOCATION_PROFILES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data) {
        setAllocationProfiles(data.allocationProfiles)
      }
    },
  })

  const handleUserTypeChange = value => {
    if (value === ROLE.spaceAdmin) {
      // get workspaces
      setShowWorkspace(true)
      setShowAllocation(false)
      getWorkspaces()
    } else if (value === ROLE.spaceUser) {
      setShowWorkspace(false)
      setShowAllocation(true)
      getAllocationProfiles()
    } else {
      setShowWorkspace(false)
      setShowAllocation(false)
      setWorkspaces(undefined)
      setAllocationProfiles(undefined)
    }
  }

  const handleValidUserSubmit = values => {
    let userInput = {
      fName: values.firstName,
      lName: values.lastName,
      email: values.email,
      role: values.role ? values.role : "",
      designation: values.designation ? values.designation : "",
      department: values.department ? values.department : "",
      mobile: values.phoneNumber ? values.phoneNumber : "",
      employeeID: values.employeeID ? values.employeeID : "",
      userGroup: values.userGroup ? values.userGroup : "",
      lastLoggedIn: values.lastLoggedIn ? values.lastLoggedIn : "",
      isActive: values.isActive ? values.isActive : false,
      isSubUser: true,
    }

    if (values.role === ROLE.spaceAdmin) {
      userInput.allocationProfileIDs = null
      userInput.workspaceIDs = values.workspaceIDs ? values.workspaceIDs : null
    } else if (values.role === ROLE.spaceUser) {
      userInput.workspaceIDs = null
      userInput.allocationProfileIDs = values.allocationProfileIDs
        ? values.allocationProfileIDs
        : null
    } else {
      userInput.allocationProfileIDs = null
      userInput.workspaceIDs = null
    }
    if (editMode) {
      onUpdate(userInput, data.id)
    } else {
      onSave(userInput)
    }
  }

  return (
    <Modal
      isOpen={showModal}
      scrollable={true}
      size="lg"
      backdrop="static"
      toggle={handleClose}
    >
      <ModalHeader tag="h4" toggle={handleClose}>
        {editMode ? "Update User" : "New User"}
      </ModalHeader>

      <ModalBody className="px-4">
        <Form
          id="user-form"
          onFinish={handleValidUserSubmit}
          initialValues={{
            ["firstName"]: data ? data.fName : "",
            ["lastName"]: data ? data.lName : "",
            ["email"]: data ? data.email : "",
            ["role"]: data ? data.role : undefined,
            ["designation"]: data ? data.designation : "",
            ["department"]: data ? data.department : "",
            ["phoneNumber"]: data ? data.mobile : "",
            ["employeeID"]: data ? data.employeeID : "",
            ["isActive"]: data ? data.isActive : false,
            ["workspaceIDs"]:
              data && data.workspaces !== null
                ? data.workspaces.map(workspace => workspace.id)
                : undefined,
            ["allocationProfileIDs"]:
              data && data.allocationProfiles !== null
                ? data.allocationProfiles.map(
                    allocationProfile => allocationProfile.id
                  )
                : undefined,
          }}
        >
          <Divider
            orientation="left"
            className="font-size-13"
            style={{ borderWidth: 2, borderColor: "#999", color: "#555" }}
          >
            User Primary Details
          </Divider>
          <Row gutter={26} className="my-1">
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                First Name <span className="text-danger">*</span>
              </Text>
            </Col>
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                Last Name <span className="text-danger">*</span>
              </Text>
            </Col>
          </Row>
          <Row gutter={26}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: "Please enter first name" }]}
              >
                <Input
                  className="rounded-3"
                  size="middle"
                  placeholder="Enter first name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: "Please enter last name" }]}
              >
                <Input
                  className="rounded-3"
                  size="middle"
                  placeholder="Enter last name"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={26} className="my-1">
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                Email ID <span className="text-danger">*</span>
              </Text>
            </Col>
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                User Type <span className="text-danger">*</span>
              </Text>
            </Col>
          </Row>
          <Row gutter={26}>
            <Col span={12}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input
                  className="rounded-3"
                  size="middle"
                  placeholder="Enter email name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="role"
                rules={[
                  { required: true, message: "Please select a user type" },
                ]}
              >
                <Select
                  suffixIcon={<i className="fas fa-chevron-down"></i>}
                  className="subtask-hover rounded-3"
                  style={{
                    height: "32px",
                    border: "1px solid #d9d9d9",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  placeholder="Select a user type"
                  bordered={false}
                  dropdownStyle={{ borderRadius: "6px" }}
                  onChange={handleUserTypeChange}
                >
                  {customRoles?.map(customRole => {
                    return (
                      <Select.Option
                        key={customRole.id}
                        value={customRole.name}
                      >
                        {customRole.name.replace(/_/g, " ")}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row className="my-1">
            <Col span={24} hidden={!showWorksapce}>
              <Text className="font-size-12 text-black-50" strong>
                Workspaces <span className="text-danger">*</span>
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={24} hidden={!showWorksapce}>
              <Form.Item name="workspaceIDs">
                <Select
                  suffixIcon={<i className="fas fa-chevron-down"></i>}
                  mode="multiple"
                  className="subtask-hover rounded-3"
                  style={{
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  placeholder="Select one or more workspaces"
                  bordered={false}
                  dropdownStyle={{ borderRadius: "6px" }}
                >
                  {workspaces
                    ? workspaces.map(workspace => {
                        return (
                          <Select.Option
                            key={workspace.id}
                            value={workspace.id}
                          >
                            {workspace.name}
                          </Select.Option>
                        )
                      })
                    : ""}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row className="my-1" hidden={!showAllocation}>
            <Col span={24}>
              <Text className="font-size-12 text-black-50" strong>
                Allocation Profiles <span className="text-danger">*</span>
              </Text>
            </Col>
          </Row>
          <Row hidden={!showAllocation}>
            <Col span={24}>
              <Form.Item name="allocationProfileIDs">
                <Select
                  suffixIcon={<i className="fas fa-chevron-down"></i>}
                  mode="multiple"
                  className="subtask-hover rounded-3"
                  style={{
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  placeholder="Select one or more workspaces"
                  bordered={false}
                  dropdownStyle={{ borderRadius: "6px" }}
                >
                  {allocationProfiles
                    ? allocationProfiles.map(allocationProfile => {
                        return (
                          <Select.Option
                            key={allocationProfile.id}
                            value={allocationProfile.id}
                          >
                            {allocationProfile.name}
                          </Select.Option>
                        )
                      })
                    : ""}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={26} className="my-1">
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                Designation
              </Text>
            </Col>
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                Department
              </Text>
            </Col>
          </Row>
          <Row gutter={26}>
            <Col span={12}>
              <Form.Item name="designation" initialValue="">
                <Input className="rounded-3" size="middle" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="department" initialValue="">
                <Input className="rounded-3" size="middle" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={26} className="my-1">
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                Phone number
              </Text>
            </Col>
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                Employee ID
              </Text>
            </Col>
          </Row>
          <Row gutter={26}>
            <Col span={12}>
              <Form.Item name="phoneNumber" initialValue="">
                <Input className="rounded-3" size="middle" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="employeeID" initialValue="">
                <Input className="rounded-3" size="middle" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            className="mb-5"
            name="isActive"
            initialValue="checked"
            valuePropName="checked"
            style={{ height: "10px" }}
            label={<span className="font-size-12">Active</span>}
          >
            <Switch size="small" />
          </Form.Item>

          <Divider
            orientation="left"
            className="font-size-13 d-none"
            style={{ borderWidth: 2, borderColor: "#999", color: "#555" }}
          >
            User Groups to include Users in
          </Divider>
          <Row gutter={26} className="mb-2 d-none">
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                Select User group
              </Text>
            </Col>
          </Row>
          <Row className="d-none">
            <Col span={24}>
              <Form.Item name="userGroups">
                <Select
                  suffixIcon={<i className="fas fa-chevron-down"></i>}
                  className="subtask-hover rounded-3"
                  style={{
                    height: "32px",
                    border: "1px solid #d9d9d9",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  placeholder="Select a user group"
                  bordered={false}
                  dropdownStyle={{ borderRadius: "6px" }}
                >
                  <Select.Option value="one">Group One</Select.Option>
                  <Select.Option value="two">Group Two</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div className="modal-footer d-none">
            <div
              onClick={handleClose}
              className="btn btn-outline-secondary rounded-3"
            >
              Cancel
            </div>
            <button className="btn btn-success rounded-3" type="submit">
              {editMode ? "Update" : "Save"}
            </button>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div
          onClick={handleClose}
          className="btn btn-outline-secondary rounded-3"
        >
          Cancel
        </div>
        <button
          className="btn btn-success rounded-3"
          type="submit"
          form="user-form"
        >
          {editMode ? "Update" : "Save"}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default UserModal
