import PropTypes from "prop-types"
import React, { useState, useEffect, useContext } from "react"
import { connect } from "react-redux"
import { useHistory, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import logo from "../../assets/images/concize_logo_white.png"
import { Select } from "antd"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import toast, { Toaster } from "react-hot-toast"

const { Option } = Select

import { WORKSPACES, GET_LAST_WORKSPACE, SPACES } from "../../graphql/queries"
import { UPDATE_LAST_WORKSPACE } from "../../graphql/mutation"
import LeftSidebarContent from "./leftSidebarContent"
import { CalendarContext } from "contexts/CalendarContext"

const Sidebar = props => {
  const history = useHistory()
  const [workspaces, setWorkspaces] = useState(undefined)
  const [lastWorkspace, setLastWorkspace] = useState(undefined)
  const [spaces, setSpaces] = useState(undefined)
  const { updateWorkspaceCtx } = useContext(CalendarContext)

  // SECTION APIS
  const { refetch: lastWorkspaceRefetch, data: lastWorkspaceData } = useQuery(
    GET_LAST_WORKSPACE,
    {
      onCompleted: data => {
        if (data && data.getLastWorkspace) {
          setLastWorkspace(data.getLastWorkspace)
          updateWorkspaceCtx(data.getLastWorkspace)
        }
      },
      onError: error => {
        if (error && error.message) {
          toast(error.message, {
            duration: 6000,
          })
        }
        console.log("error", error)
      },
    }
  )

  useEffect(() => {
    if (lastWorkspaceData && lastWorkspaceData.getLastWorkspace) {
      getSpaces({
        variables: {
          workspaceID: lastWorkspaceData.getLastWorkspace.id,
        },
      })
      setLastWorkspace(lastWorkspaceData.getLastWorkspace)
    } else {
      setLastWorkspace(undefined)
    }
  }, [lastWorkspaceData])

  const [getSpaces, { data: spaceData }] = useLazyQuery(SPACES, {
    fetchPolicy: "cache-and-network",
    onError: error => {
      console.log("error", error)
    },
  })

  useEffect(() => {
    if (spaceData) {
      setSpaces(spaceData.spaces)
    }
  }, [spaceData])

  const { refetch, data } = useQuery(WORKSPACES, {
    onError: error => {
      console.log("error", error)
    },
  })

  useEffect(() => {
    if (data && data.workspaces) {
      setWorkspaces(data.workspaces)
    } else {
      setWorkspaces(null)
    }
  }, [data])

  const [updateLastWorkspace] = useMutation(UPDATE_LAST_WORKSPACE, {
    onCompleted: data => {
      if (data && data.updateLastWorkspace) {
        let workspace = data.updateLastWorkspace.lastWorkspace
        let currentWorkspaceID = workspace.id
        history.push(`/${currentWorkspaceID}/my-tasks`)
        updateWorkspaceCtx(workspace)
        setLastWorkspace(workspace)
        lastWorkspaceRefetch()
        getSpaces({
          variables: {
            workspaceID: workspace.id,
          },
        })
      }
    },
    onError: error => {
      console.log("error", error)
    },
  })

  const handleWorkspaceChange = option => {
    updateLastWorkspace({
      variables: {
        workspaceID: option.value,
      },
    })
  }

  const handleDropdownVisible = open => {
    if (open) {
      refetch()
    }
  }

  const handleUpdateSpaces = updatedSpaces => {
    setSpaces([...updatedSpaces])
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div
          className="navbar-brand-box mt-2"
          style={{
            height: "fit-content",
            padding: "0rem 1rem",
          }}
        >
          <div className="mb-3">
            <span>
              <img src={logo} height="60" className="mb-1" />
            </span>
            <div
              className="font-size-14 font-weight-semibold text-white text-uppercase mb-1"
              style={{
                letterSpacing: "2px",
              }}
            >
              Concize
            </div>
            <div
              style={{
                color: "#fafafa",
                fontSize: "10px",
                textTransform: "uppercase",
                fontWeight: 600,
              }}
            >
              Collaborate, Market & Analyze
            </div>
          </div>

          {/* NOTE Workspace Select */}
          <div>
            <Select
              bordered={false}
              size="small"
              className="workspace-select font-size-12"
              value={lastWorkspace ? lastWorkspace.id : null}
              suffixIcon={<i className="fa fa-chevron-down text-white"></i>}
              placeholder="Select a workspace"
              onDropdownVisibleChange={handleDropdownVisible}
              onChange={(_, option) => {
                handleWorkspaceChange(option)
              }}
              dropdownStyle={{
                borderRadius: "6px",
                fontSize: "12px",
              }}
            >
              {workspaces
                ? workspaces.map(workspace => {
                    return (
                      <Option
                        key={workspace.id}
                        id={workspace.id}
                        value={workspace.id}
                      >
                        {workspace.name}
                      </Option>
                    )
                  })
                : null}
            </Select>
          </div>
        </div>

        <div data-simplebar className="h-100">
          <LeftSidebarContent
            props={props}
            lastWorkspace={lastWorkspace}
            spaces={spaces ? spaces : null}
            handleUpdateSpaces={handleUpdateSpaces}
            pathName={props.location.pathname}
          />
        </div>
        <div className="sidebar-background"></div>
      </div>
      <Toaster />
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
