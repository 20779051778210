import React, { useState, useEffect, memo } from "react"
import { useDebounce } from "use-debounce/lib"
import ReactDragListView from "react-drag-listview/lib/index.js"
import ConfirmDeleteModal from "../../../components/Dialog/confirmDeleteModal"
import { Table, Menu, Dropdown } from "antd"
import "./tableStyle.css"
import AssigneeDropdown from "./customField/components/assigneeDropdown"

import { IconButton } from "@material-ui/core"
import {
  UPDATE_TASK,
  DELETE_TASK,
  UPDATE_CUSTOM_FIELD,
  ADD_ASSIGNEE,
  REMOVE_ASSIGNEE,
} from "../../../graphql/mutation"
import { useMutation } from "@apollo/client"
import TableTextField from "./tableCustomFieldComponents/tableTextField"
import TableDateField from "./tableCustomFieldComponents/tableDateField"
import TableCheckboxField from "./tableCustomFieldComponents/tableCheckboxField"
import TableDropdownField from "./tableCustomFieldComponents/tableDropdownField"
import TableDropdownMultiField from "./tableCustomFieldComponents/tableDropdownMultiField"
import TableNumberField from "./tableCustomFieldComponents/tableNumberField"
import TableTextAreaField from "./tableCustomFieldComponents/tableTextAreaField"
import DefaultTitleField from "./customField/components/defaultFields/defaultTitleField"
import DefaultDatePicker from "./customField/components/defaultFields/defaultDatePicker"
import DefaultDropdown from "./customField/components/defaultFields/defaultDropdown"

const TaskTable = ({
  loading,
  openDrawer,
  tasks,
  handleOpenDrawer,
  updateInlineMainTaskList,
  updateMainTask,
  customFields,
  handleEditCustomField,
  filteredTasks,
  updateMainTaskList,
  handleChangeSelectedTask,
  totalTasksCount,
  loadMoreTasks,
}) => {
  const params = new URL(window.location.href).searchParams
  const taskID = params.get("taskID") ? params.get("taskID") : ""

  useEffect(() => {
    if (params && params.has("taskID") && tasks && tasks.length) {
      tasks.some(function (task, index) {
        if (task.id === params.get("taskID")) {
          showDetails(task, index)
          return true
        }
      })
    }
  }, [tasks])

  useEffect(() => {
    if (
      taskID !== "" &&
      taskID !== null &&
      taskID !== undefined &&
      tasks &&
      tasks.length > 0 &&
      tasks.findIndex(t => t.id === taskID) > -1
    ) {
      tasks.some(function (task, index) {
        if (task.id === taskID) {
          console.log("task, index", task, index)
          showDetails(task, index)
          return true
        }
      })
    }
  }, [taskID])

  const menu = customField => (
    <Menu
      className="rounded-3"
      onClick={e => {
        handleMenuClick(e.key, customField)
      }}
    >
      <Menu.ItemGroup title={<span className="mx-2">Edit custom field</span>}>
        <Menu.Item key="0" className="d-none">
          <div>
            <i className="fal fa-eye-slash me-2"></i>
            Hide Column
          </div>
        </Menu.Item>
        <Menu.Item key="1">
          <div>
            <i className="fal fa-cog me-2"></i>
            Edit Field
          </div>
        </Menu.Item>
        {/* <Menu.Divider /> */}
        <Menu.Item key="3" className="d-none">
          <div className="text-danger">
            <i className="fal fa-trash-alt me-2"></i>
            Delete Field
          </div>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  const [task, setTask] = useState(undefined)
  const [updatedTask] = useDebounce(task, 100)

  const [showDeletDialog, setShowDeleteDialog] = useState(false)
  const [taskToBeDeleted, setTaskToBeDeleted] = useState(undefined)
  const [auditInput, setAuditInput] = useState(undefined)

  const handleMenuClick = (key, customField) => {
    if (key === "1") {
      handleEditCustomField(customField)
    }
  }

  // SECTION APIS
  const [updateTask] = useMutation(UPDATE_TASK, {
    onCompleted: data => {
      if (data && data.updateTask) {
        let updatedTask = data.updateTask
        updateInlineMainTaskList(updatedTask, updatedTask.id)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [deleteTask] = useMutation(DELETE_TASK, {
    onCompleted: data => {
      if (data && data.deleteTask) {
        let deletedTask = data.deleteTask
        updateMainTask(deletedTask.id)
        setShowDeleteDialog(false)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [updateCustomField] = useMutation(UPDATE_CUSTOM_FIELD, {
    onCompleted: data => {
      if (data && data.updateCustomField) {
        updateInlineMainTaskList(
          data.updateCustomField,
          data.updateCustomField.id
        )
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  useEffect(() => {
    const taskID = updatedTask?.id
    if (updatedTask && taskID) {
      delete updatedTask.id
      delete updatedTask.parentID
      delete updatedTask.customFieldValues
      delete updatedTask.__typename
      delete updatedTask.space

      updateTask({
        variables: {
          input: updatedTask,
          taskID,
          auditInput,
        },
      })
    }
  }, [updatedTask])

  const handleInlineFieldChanges = inputTask => {
    delete inputTask.assignees
    delete inputTask.__typename
    delete inputTask.subTasks
    delete inputTask.comments
    delete inputTask.audit
    delete inputTask.customFieldValues

    setTask({ ...inputTask })
  }

  const handleInlineTitleChange = (record, title) => {
    let taskValue = { ...record }
    taskValue["title"] = title

    const auditInput = {
      auditType: 2,
      from: record.title,
      to: title,
    }
    setAuditInput(auditInput)

    if (record.title !== title) {
      handleInlineFieldChanges(taskValue)
    }
  }

  const handleInlineStartDateChange = (record, dateStr) => {
    let taskValue = { ...record }
    taskValue["startDate"] = dateStr

    const auditInput = {
      auditType: 4,
      from: record.startDate,
      to: dateStr,
    }
    setAuditInput(auditInput)

    handleInlineFieldChanges(taskValue)
  }

  const [addAssignee] = useMutation(ADD_ASSIGNEE, {
    onCompleted: data => {
      if (data && data.addAssignee) {
        if (data.addAssignee.isSubtask) {
          handleChangeSelectedTask(data.addAssignee)
        } else {
          updateMainTaskList(data.addAssignee)
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })
  const handleAssigneeSelect = (record, event) => {
    let taskValue = { ...record }
    console.log("taskValue", taskValue)
    console.log("key", event)
    let auditInput = {
      auditType: 3,
      to: event.label,
    }

    addAssignee({
      variables: {
        input: {
          taskID: record.id,
          userID: event.key,
        },
        auditInput,
      },
    })
  }

  const handleAssigneeDeselect = (data, userID) => {
    if (userID) {
      removeAssignee({
        variables: {
          userID,
          taskID: data.id,
        },
      })
    }
  }
  const [removeAssignee] = useMutation(REMOVE_ASSIGNEE, {
    onCompleted: data => {
      if (data && data.removeAssignee) {
        if (data.removeAssignee.parentID === "") {
          updateMainTaskList(data.removeAssignee)
        } else {
          handleChangeSelectedTask(data.removeAssignee)
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const handleInlineEndDateChange = (record, dateStr) => {
    let taskValue = { ...record }
    taskValue["endDate"] = dateStr

    const auditInput = {
      auditType: 4,
      from: record.endDate,
      to: dateStr,
    }
    setAuditInput(auditInput)

    handleInlineFieldChanges(taskValue)
  }

  const handleInlineStatusChange = (record, value) => {
    let taskValue = { ...record }
    taskValue["status"] = value

    const auditInput = {
      auditType: 5,
      from: record.status,
      to: value,
    }
    setAuditInput(auditInput)

    handleInlineFieldChanges(taskValue)
  }

  let defaultColumnsData = [
    /* {
      key: "1",
      title: "",
      hidden: true,
      dataIndex: "image",
      render: (text, record, index) => (
        <div
          onClick={() => {
            showDetails(record, index)
          }}
        >
          <img src={companies["img1"]} alt="" className="avatar-sm" />
        </div>
      ),
    },
    */
    {
      key: "2",
      width: 250,
      title: "Task name",
      dataIndex: ["title", "description"],
      render: (text, record) => (
        <DefaultTitleField
          data={record}
          handleInlineTitleChange={handleInlineTitleChange}
        />
      ),
    },
    {
      key: "3",
      width: 250,
      title: "Assignee",
      dataIndex: "Assignee",
      render: (_, record) => (
        <AssigneeDropdown
          name="Assignee"
          data={record}
          handleAssigneeSelect={handleAssigneeSelect}
          handleAssigneeDeselect={handleAssigneeDeselect}
        />
      ),
    },
    {
      key: "4",
      width: 200,
      title: "End Date",
      dataIndex: "endDate",
      render: (_, record) => (
        <DefaultDatePicker
          name="End Date"
          data={record}
          handleChange={handleInlineEndDateChange}
        />
      ),
    },
    {
      key: "5",
      title: "Status",
      width: 200,
      dataIndex: "status",
      render: (_, record) => (
        <DefaultDropdown
          data={record}
          handleInlineStatusChange={handleInlineStatusChange}
        />
      ),
    },
    /*{
      key: "6",
      title: "Team",
      dataIndex: "assignees",
      render: assignees => (
        <div className="avatar-group">
          {assignees && assignees !== null
            ? map(assignees, (member, index) => (
                <div className="avatar-group-item" key={"_team_" + member.id}>
                  <Link
                    to="#"
                    className="d-inline-block"
                    id={"member" + member.id}
                  >
                    <div className="avatar-xs">
                      <span
                        className={
                          "avatar-title rounded-circle bg-soft bg-success text-success font-size-16"
                        }
                      >
                        {member.user ? member.user.fName.charAt(0) : ""}
                      </span>
                      <UncontrolledTooltip
                        placement="top"
                        target={"member" + member.id}
                      >
                        {member.user.fName}
                      </UncontrolledTooltip>
                    </div>
                  </Link>
                </div>
              ))
            : null}
        </div>
      ),
    },*/
    {
      key: "7",
      width: 100,
      title: "Action",
      render: record => (
        <IconButton
          onClick={() => {
            setTaskToBeDeleted(record)
            setShowDeleteDialog(true)
          }}
        >
          <i className="fas fa-trash font-size-14 text-danger"></i>
        </IconButton>
      ),
    },
  ]

  // NOTE State Variables
  const [columns, setColumns] = useState([])
  const [data, setData] = useState(undefined)
  const [color, setColor] = useState("")
  const [taskIndex, setTaskIndex] = useState(undefined)

  useEffect(() => {
    if (!openDrawer) {
      setColor("")
    }
  }, [openDrawer])

  function getValue(customField, record) {
    let value
    if (record && record.customFieldValues && record.customFieldValues.length) {
      record.customFieldValues.forEach(ele => {
        if (ele.custFieldID === customField.id) {
          value = ele.value
        }
      })
    } else {
      value = ""
    }

    return value
  }

  const customFieldMenu = customField => {
    return (
      <div className="d-flex justify-content-between align-items-center task-title">
        <div>{customField.fieldName}</div>
        <Dropdown
          overlay={() => menu(customField)}
          trigger={["click"]}
          placement="bottomRight"
        >
          <div className="subtask-hover px-2 rounded-3 task-ellipsis ms-2">
            <i className="fa fa-ellipsis-v"></i>
          </div>
        </Dropdown>
      </div>
    )
  }

  function getRespectiveField(customField) {
    let field
    switch (customField.fieldType) {
      case "Date": {
        field = {
          width: 200,
          key: customField.id,
          title: () => {
            return customFieldMenu(customField)
          },
          render: record => {
            let val = getValue(customField, record)
            return (
              <TableDateField
                customField={customField}
                record={record}
                value={val}
                updateCustomField={updateCustomField}
              />
            )
          },
        }
        break
      }
      case "Checkbox": {
        field = {
          key: customField.id,
          width: 150,
          title: () => {
            return customFieldMenu(customField)
          },
          render: record => {
            let val = getValue(customField, record)
            return (
              <TableCheckboxField
                customField={customField}
                record={record}
                value={val}
                updateCustomField={updateCustomField}
              />
            )
          },
        }
        break
      }

      case "Dropdown": {
        field = {
          width: 200,
          key: customField.id,
          title: () => {
            return customFieldMenu(customField)
          },
          render: (_, record) => {
            let val = getValue(customField, record)
            return (
              <TableDropdownField
                customField={customField}
                record={record}
                value={val}
                updateCustomField={updateCustomField}
              />
            )
          },
        }
        break
      }

      case "Dropdown Multi": {
        field = {
          width: 200,
          key: customField.id,
          title: () => {
            return customFieldMenu(customField)
          },
          render: (_, record) => {
            let val = getValue(customField, record)
            return (
              <TableDropdownMultiField
                customField={customField}
                record={record}
                value={val}
                updateCustomField={updateCustomField}
              />
            )
          },
        }
        break
      }

      case "Text": {
        field = {
          width: 200,
          key: customField.id,
          title: () => {
            return customFieldMenu(customField)
          },
          render: (_, record) => {
            let val = getValue(customField, record)
            return (
              <TableTextField
                customField={customField}
                record={record}
                value={val}
                updateCustomField={updateCustomField}
              />
            )
          },
        }
        break
      }

      case "Text Area": {
        field = {
          width: 200,
          key: customField.id,
          title: () => {
            return customFieldMenu(customField)
          },
          render: (_, record) => {
            let val = getValue(customField, record)
            return (
              <TableTextAreaField
                customField={customField}
                record={record}
                value={val}
                updateCustomField={updateCustomField}
              />
            )
          },
        }
        break
      }

      case "Number": {
        field = {
          width: 150,
          key: customField.id,
          title: () => {
            return customFieldMenu(customField)
          },
          render: (_, record) => {
            let val = getValue(customField, record)
            return (
              <TableNumberField
                customField={customField}
                record={record}
                value={val}
                updateCustomField={updateCustomField}
              />
            )
          },
        }
        break
      }

      default:
        break
    }
    return field
  }

  useEffect(() => {
    if (customFields) {
      let taskColumn = defaultColumnsData
      let newCols = []
      customFields.forEach(customField => {
        let colData = getRespectiveField(customField)
        newCols.push(colData)
      })
      // moving Action task column to last
      let actionIndex = taskColumn.findIndex(
        col => col.key === "7" && col.title === "Action"
      )
      taskColumn.splice(actionIndex, 0, ...newCols)
      setColumns(taskColumn)
    } else {
      setColumns([...defaultColumnsData])
    }
  }, [customFields])

  useEffect(() => {
    if (tasks) {
      setData([...tasks])
    } else {
      setData([])
    }
  }, [tasks])

  const scrollEvent = event => {
    // checking whether a selector is well defined
    let maxScroll = event.target.scrollHeight - event.target.clientHeight - 50
    let currentScroll = event.target.scrollTop
    console.log("maxScroll", maxScroll, currentScroll, totalTasksCount)
    if (
      maxScroll > 0 &&
      currentScroll > 0 &&
      currentScroll > maxScroll &&
      tasks &&
      tasks.length < totalTasksCount
    ) {
      loadMoreTasks()
      var tableContent = document.querySelector(".ant-table-body")
      tableContent.removeEventListener("scroll", scrollEvent)
    }
  }

  useEffect(() => {
    var tableContent = document.querySelector(".ant-table-body")
    tableContent.removeEventListener("scroll", scrollEvent)
    if (
      tasks === null ||
      tasks === undefined ||
      tasks.length <= 0 ||
      (totalTasksCount <= 20 && totalTasksCount !== 0)
    ) {
      return
    }
    console.log("totalTasksCount", totalTasksCount)
    tableContent.addEventListener("scroll", scrollEvent)
  }, [tasks, totalTasksCount])

  // * Handle Open right drawer
  const showDetails = (task, index) => {
    handleOpenDrawer(task, index)

    setColor("table-row-dark")
    // setSelectedTask(task)
    setTaskIndex(index)
  }

  /**
   * * Handle row order change function
   * TODO Use this function to change task rows
   */
  const dragRowProps = {
    onDragEnd(fromIndex, toIndex) {
      /*  const newData = [...data]
          const item = newData.splice(fromIndex, 1)[0]
          newData.splice(toIndex, 0, item)
    
          let rowOrderIDs = []
          newData.forEach(ele => rowOrderIDs.push(ele.id))

          setData(newData) */
    },
    handleSelector: "tr",
  }

  /**
   * * Handle column order change function
   * TODO Use this function to change task columns
   */
  const dragColumnProps = {
    onDragEnd(fromIndex, toIndex) {
      /* const newColumns = [...columns]
      const item = newColumns.splice(fromIndex, 1)[0]
      newColumns.splice(toIndex, 0, item)

      let newColumnIDs = []
      newColumns.forEach(ele => {
        newColumnIDs.push(ele.key)
      })

      changeTaskColumnOrder({
        variables: {
          spaceID,
          columnOrderIDs: newColumnIDs,
        },
      }) */
      // setColumns(newColumns)
    },
    nodeSelector: "th",
  }

  const handleDeletModalClose = () => {
    setTaskToBeDeleted(undefined)
    setShowDeleteDialog(false)
  }

  const handleDeleteTask = () => {
    if (taskToBeDeleted) {
      deleteTask({
        variables: {
          taskID: taskToBeDeleted.id,
        },
      })
    }
  }

  return (
    <div>
      <ReactDragListView {...dragRowProps}>
        <ReactDragListView.DragColumn {...dragColumnProps}>
          <Table
            loading={loading}
            rowClassName={(_, index) => (index === taskIndex ? color : null)}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => {
                  let target = e.target.localName
                  if (target === "td" || target === "p") {
                    showDetails(record, rowIndex)
                  }
                },
              }
            }}
            scroll={{
              // x: "100vw",
              y: "calc(100vh - 220px)",
            }}
            columns={columns}
            pagination={false}
            dataSource={data}
          />
        </ReactDragListView.DragColumn>
      </ReactDragListView>
      <ConfirmDeleteModal
        showDialog={showDeletDialog}
        handleClose={handleDeletModalClose}
        onConfirm={handleDeleteTask}
        title={`Delete Task`}
        subText={`Are you sure you want to delete task "${taskToBeDeleted?.title}" ?`}
      />
    </div>
  )
}

export default memo(TaskTable)
