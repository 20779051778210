import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import Profile from "../pages/Profile/profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// PAGES
import TaskList from "../pages/TaskList/taskList"
import Dashboard from "../pages/Dashboard/dashboard"
import DashboardPage from "../pages/DashboardPage/dashboardPage"
import ExternalDashboardPage from "../pages/DashboardPage/externalDashboardPage"
import Settings from "../pages/Settings/settings"
import Users from "../pages/Users/users"
import AllocationProfile from "../pages/Allocation Profile/allocationProfile"
import Workspace from "../pages/Workspace/workspace"
import TrackTime from "../pages/TrackTime/trackTime"
import Calendar from "../pages/Calendar/calendar"
import Accounts from "../pages/Accounts/accounts"
import CustomFields from "../pages/CustomFields/customFields"
import Campaigns from "../pages/Campaigns/campaigns"
import NewLogin from "pages/Authentication/newLogin"
import NewForgotPassword from "pages/Authentication/newForgotPassword"

const userRoutes = [
  { path: "/:workspaceID/dashboards", component: Dashboard },
  { path: "/:workspaceID/dashboards/:dashboardID", component: DashboardPage }, // id is dashboardID

  // Profile
  { path: "/profile", component: Profile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/profile" /> },

  // Workspace
  { path: "/workspace", component: Workspace },
  { path: "/workspace/:workspaceID", component: Dashboard },

  { path: "/:workspaceID/track-time", component: TrackTime },
  { path: "/:workspaceID/calendar", component: Calendar },

  // Custom id tasks list
  // { path: "/tasks-list/:id", component: TasksList },
  { path: "/:workspaceID/space-list/:id", component: TaskList },
  { path: "/:workspaceID/my-tasks", component: TaskList },

  { path: "/settings", component: Settings },
  { path: "/users", component: Users },
  { path: "/allocation-profile", component: AllocationProfile },
  { path: "/accounts", component: Accounts },
  { path: "/custom-fields", component: CustomFields },
  { path: "/campaigns", component: Campaigns },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: NewLogin },
  { path: "/forgot-password", component: NewForgotPassword },
  { path: "/register", component: Register },
  {
    path: "/external/dashboard/:dashboardID",
    component: ExternalDashboardPage,
  }, // id is dashboardID
]

export { userRoutes, authRoutes }
