import React, { useState, useEffect, useRef } from "react"
import { Row, Col } from "antd"
import CustomToolTip from "./customToolTip"

import { useMutation } from "@apollo/client"
import {
  ADD_COMMENT,
  UPDATE_COMMENT,
  ADD_AUDIT,
} from "../../../graphql/mutation"

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton,
} from "@draft-js-plugins/buttons"
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js"
import Editor from "@draft-js-plugins/editor"
// import { Editor } from "react-draft-wysiwyg"
import createToolbarPlugin from "@draft-js-plugins/static-toolbar"
import createLinkifyPlugin from "@draft-js-plugins/linkify"
import createEmojiPlugin from "@draft-js-plugins/emoji"
import "@draft-js-plugins/emoji/lib/plugin.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "@draft-js-plugins/mention"
import "@draft-js-plugins/mention/lib/plugin.css"
import "@draft-js-plugins/static-toolbar/lib/plugin.css"
import { useHistory } from "react-router-dom"

// const mentionPlugin = createMentionPlugin()
const toolbarPlugin = createToolbarPlugin()
const linkifyPlugin = createLinkifyPlugin()
const emojiPlugin = createEmojiPlugin({
  useNativeArt: true,
})

const mentionPlugin = createMentionPlugin({
  entityMutability: "IMMUTABLE",
  // theme: mentionsStyles,
  mentionPrefix: "@",
  supportWhitespace: true,
})

const plugins = [linkifyPlugin, emojiPlugin, mentionPlugin, toolbarPlugin]

const { MentionSuggestions } = mentionPlugin
const { EmojiSelect } = emojiPlugin

const Comments = ({
  users,
  task,
  updateMainTaskList,
  handleChangeDrawerDatas,
  openDrawer,
  commentData,
}) => {
  const [commentText, setCommentText] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [editComment, setEditComment] = useState(undefined)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [selectedMentions, setSelectedMentions] = useState(null)
  const history = useHistory()
  const params = new URL(window.location.href).searchParams

  const { MentionSuggestions, EmojiSelect, Toolbar, plugins } =
    React.useMemo(() => {
      const mentionPlugin = createMentionPlugin({
        entityMutability: "IMMUTABLE",
        // theme: mentionsStyles,
        mentionPrefix: "@",
        supportWhitespace: true,
      })
      // eslint-disable-next-line no-shadow
      const { MentionSuggestions } = mentionPlugin
      const { EmojiSelect } = emojiPlugin
      const toolbarPlugin = createToolbarPlugin()
      const { Toolbar } = toolbarPlugin
      // eslint-disable-next-line no-shadow
      const plugins = [mentionPlugin, linkifyPlugin, emojiPlugin, toolbarPlugin]
      return { plugins, MentionSuggestions, EmojiSelect, Toolbar }
    }, [])

  const [mentions, setMentions] = useState([])

  useEffect(() => {
    if (users && users.length) {
      let newMentions = []
      users.forEach(user => {
        newMentions.push({
          name: `${user.fName} ${user.lName}`,
          id: user.id,
        })
      })

      setMentions(newMentions)
    }
  }, [users])

  const ref = useRef()

  const [open, setOpen] = useState(false)
  const [suggestions, setSuggestions] = useState(mentions)

  const onOpenChange = val => {
    setOpen(val)
  }
  const onSearchChange = ({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, mentions))
  }

  useEffect(() => {
    if (commentData) {
      let fromRawData
      try {
        let parsedData = JSON.parse(commentData.comment)
        fromRawData = convertFromRaw(parsedData)
      } catch (e) {
        fromRawData = ContentState.createFromText(commentData.comment)
      }

      setEditorState(EditorState.createWithContent(fromRawData))
      setCommentText(commentData.comment)
      setIsEdit(true)
      setEditComment(commentData)
    } else {
      // setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()))
      setCommentText("")
      setIsEdit(false)
      setEditComment(undefined)
    }
    if (!openDrawer) {
      setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()))
    }
  }, [commentData, openDrawer])

  // SECTION APIS
  const [addComment] = useMutation(ADD_COMMENT, {
    onCompleted: data => {
      if (data && data.addComment) {
        let taskWithNewComment = data.addComment
        history.push({
          search: `?taskID=${params.get("taskID")}#${
            data.addComment.comments[data.addComment.comments.length - 1].id
          }`,
        })
        if (taskWithNewComment.isSubtask) {
          handleChangeDrawerDatas(taskWithNewComment)
        } else {
          updateMainTaskList(taskWithNewComment)
        }
        setEditorState(EditorState.createEmpty())
        setCommentText("")
      }
      setSelectedMentions(null)
    },
    onError: err => {
      console.log("err", err)
      setSelectedMentions(null)
    },
  })

  const [updateComment] = useMutation(UPDATE_COMMENT, {
    onCompleted: data => {
      if (data && data.updateComment) {
        let taskWithUpdatedComment = data.updateComment
        if (taskWithUpdatedComment.isSubtask) {
          handleChangeDrawerDatas(taskWithUpdatedComment)
        } else {
          updateMainTaskList(taskWithUpdatedComment)
        }
      }
      setEditorState(EditorState.createEmpty())
      setCommentText("")
      setIsEdit(false)
      setEditComment(undefined)
      setSelectedMentions(null)
    },
    onError: err => {
      console.log("err", err)
      setSelectedMentions(null)
    },
  })

  const onEditorStateChange = editState => {
    const contentState = editorState.getCurrentContent()
    const currentStateData = convertToRaw(contentState)
    const richStringifyValue = JSON.stringify(currentStateData)
    setCommentText(richStringifyValue)
    setEditorState(editState)
  }

  const handleAddComment = () => {
    let input = {
      taskID: task.id,
      comment: commentText,
    }

    if (selectedMentions && selectedMentions.length) {
      input.to = selectedMentions
    }

    addComment({
      variables: {
        input,
      },
    })
  }

  const onHandleUpdateComment = () => {
    let auditInput = {
      auditType: 8,
      from: editComment.comment,
      to: commentText,
    }
    let commentTo = []
    if (commentData.to) {
      commentData.to.forEach(c => {
        commentTo.push(c.id)
      })
    }
    let input = {
      taskID: task.id,
      comment: commentText,
      to: commentTo,
    }
    if (selectedMentions && selectedMentions.length) {
      if (input.to) {
        input.to = [...input.to, ...selectedMentions]
      } else {
        input.to = selectedMentions
      }
    }
    updateComment({
      variables: {
        id: editComment.id,
        input,
        auditInput,
      },
    })
  }

  function Entry(props) {
    const { mention, theme, searchValue, isFocused, ...parentProps } = props

    return (
      <div {...parentProps}>
        <div className="d-flex justify-content-start align-items-center">
          <div
            className="avatar-xs me-2"
            style={{
              minWidth: "20px",
              minWidth: "20px",
            }}
          >
            <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-18">
              {mention.name.charAt(0)}
            </span>
          </div>

          <div>
            <div>{mention.name}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        backgroundColor: "#f9f8f8",
        position: "fixed",
        width: openDrawer ? "40%" : "0%",
        right: 0,
        bottom: "1rem",
      }}
      id="comment-box"
    >
      <Row justify="end" className="pb-2 px-4">
        <Col span={24}>
          {/* <TextArea
            allowClear
            value={commentText}
            className="rounded-3"
            autoSize={{ minRows: 3, maxRows: 10 }}
            placeholder="Enter task comment"
            onChange={handleCommentTextChange}
          /> */}
          <div
            // className={editorStyles.editor}
            onClick={() => {
              ref.current.focus()
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  marginRight: "15px",
                  marginTop: "5px",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                <Col span={24}>
                  <CustomToolTip
                    text={"Comments"}
                    subText="Add comments to the task."
                  />
                </Col>
              </div>
              <div
                className="d-flex justify-content-start align-items-center w-51"
                style={{
                  backgroundColor: "white",
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  marginBottom: "5px",
                  marginLeft: "95px",
                }}
              >
                <div
                  onClick={() => {
                    let editor = document.querySelector(
                      ".DraftEditor-editorContainer"
                    )
                    editor.classList.toggle("expand")
                  }}
                  style={{
                    width: "36px",
                    height: "34px",
                  }}
                  className="d-flex justify-content-center align-items-center cursor-pointer subtask-hover flex-wrap"
                >
                  <i
                    className="fas fa-expand font-size-15"
                    style={{ color: "#777" }}
                  ></i>
                </div>
                <EmojiSelect />

                <Toolbar>
                  {externalProps => (
                    <div>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      {/* <CodeButton {...externalProps} /> */}
                      {/* <Separator {...externalProps} /> */}
                      {/* <HeadlinesButton {...externalProps} /> */}
                      <UnorderedListButton {...externalProps} />
                      <OrderedListButton {...externalProps} />
                      {/* <BlockquoteButton {...externalProps} /> */}
                      <CodeBlockButton {...externalProps} />
                    </div>
                  )}
                </Toolbar>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  maxWidth: "480px",
                  width: "100%",
                }}
              >
                <Editor
                  editorKey={"editor"}
                  editorState={editorState}
                  onChange={onEditorStateChange}
                  plugins={plugins}
                  placeholder="Enter task comment"
                  ref={ref}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <button
                  style={{ height: "37px" }}
                  onClick={() => {
                    if (isEdit) {
                      onHandleUpdateComment()
                    } else {
                      handleAddComment()
                    }
                  }}
                  className="btn btn-primary btn-sm mt-0 rounded-3"
                  disabled={/^\s+$/.test(commentText) || commentText === ""}
                >
                  {isEdit ? "Update" : "Comment"}
                </button>
                <Col>
                  {isEdit ? (
                    <div
                      onClick={() => {
                        setIsEdit(false)
                        setEditComment(undefined)
                        setCommentText("")
                        // setEditorState(EditorState.createEmpty())
                      }}
                      className="btn btn-outline-secondary w-60px btn-sm mt-2 rounded-3 me-2"
                    >
                      Cancel
                    </div>
                  ) : null}
                </Col>
              </div>
            </div>

            <MentionSuggestions
              open={open}
              onOpenChange={onOpenChange}
              suggestions={suggestions}
              onSearchChange={onSearchChange}
              entryComponent={Entry}
              onAddMention={mention => {
                // get the mention object selected
                if (selectedMentions && selectedMentions.length) {
                  setSelectedMentions([...selectedMentions, mention.id])
                } else {
                  setSelectedMentions([mention.id])
                }
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Comments
