import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import toast, { Toaster } from "react-hot-toast"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

//Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper"

import { GlobalContext } from "../src/contexts/index"
//import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
//fakeBackend()
import jwt_decode from "jwt-decode"
import Header from "components/VerticalLayout/Header"
import Sidebar from "components/VerticalLayout/Sidebar"
import { CalendarContext } from "contexts/CalendarContext"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

//init firebase backend
initFirebaseBackend(firebaseConfig)

const App = props => {
  const [auditNomenClatureData, setauditNomenClatureData] = useState({})
  const [workspaceCtx, setWorkspaceCtx] = useState(null)
  const [profilePicSrcCtx, setProfilePicSrcCtx] = useState(null)
  const [pathName, setPathName] = useState(window.location.pathname)

  useEffect(() => {
    setPathName(window.location.pathname)
  }, [window.location.pathname])

  const isSessionExpired = () => {
    let authUser = JSON.parse(localStorage.getItem("authUser"))
    if (
      authUser &&
      authUser.stsTokenManager &&
      authUser.stsTokenManager.accessToken
    ) {
      let token = authUser.stsTokenManager.accessToken
      const { exp } = jwt_decode(token)
      let expirationTime = exp * 1000 - 60000
      if (Date.now() >= expirationTime) {
        toast.error("Your session has expired. Please login again", {
          position: "top-right",
        })
        setProfilePicSrcCtx(null)
        localStorage.removeItem("authUser")
        return true
      }
    }

    return false
  }

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }
  const updateWorkspaceCtx = workspace => {
    setWorkspaceCtx(workspace)
  }
  const updateProfilePicCtx = profileURL => {
    setProfilePicSrcCtx(profileURL)
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
      <GlobalContext.Provider
        value={{ auditNomenClatureData, setauditNomenClatureData }}
      >
        <CalendarContext.Provider
          value={{
            workspaceCtx,
            updateWorkspaceCtx,
            profilePicSrcCtx,
            updateProfilePicCtx,
          }}
        >
          <Router>
            {/* {pathName !== "/login" &&
          pathName !== "/register" &&
          pathName !== "/forgot-password" ? (
            <>
              <Header />
              <Sidebar />
            </>
          ) : null} */}
            {/* <Switch> */}
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                isSessionExpired={isSessionExpired}
                exact
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                isSessionExpired={isSessionExpired}
                exact
              />
            ))}
            {/* </Switch> */}
          </Router>
        </CalendarContext.Provider>
      </GlobalContext.Provider>
      <Toaster />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
