import React, { useState, useEffect } from "react"
import LeftNav from "../leftNav"
import RightNav from "../rightNav"
import user from "../../../../assets/images/users/empty-user.png"
import ImageGallery from "react-image-gallery"
import toast from "react-hot-toast"
import { Menu, Dropdown, Image } from "antd"

import ReactHtmlParser from "react-html-parser"
import Linkify from "react-linkify"

const LinkedInPreview = ({ linkedInAccount, content, postURL, mediaFiles }) => {
  const [images, setImages] = useState([])
  const [video, setVideo] = useState(undefined)

  useEffect(() => {
    if (mediaFiles && mediaFiles.length) {
      let newImages = []
      mediaFiles.forEach(media => {
        if (media.type === "video/mp4") {
          setVideo(media.preview)
        } else {
          newImages.push({ original: media.preview, thumbnail: media.preview })
        }
      })
      console.log("mediaFiles", mediaFiles)
      setImages(newImages)
    } else {
      setImages([])
    }
  }, [mediaFiles])

  const menu = account => (
    <Menu
      style={{
        fontFamily: "sans-serif",
      }}
      onClick={e => {
        handleLinkedinOption(e.key, account)
      }}
    >
      <Menu.Item className="mx-2 mt-1" key="0">
        <i className="fal fa-link me-1"></i> Copy post link
      </Menu.Item>
      <Menu.Item className="mx-2 mt-1" key="1">
        <i className="fal fa-external-link me-1"></i> Open
      </Menu.Item>
    </Menu>
  )

  const handleLinkedinOption = (key, account) => {
    if (!postURL) {
      toast.error("There's no post url to copy or open", {
        position: "top-right",
      })
      return
    }
    if (key === "0") {
      navigator.clipboard.writeText(postURL)
      toast.success("The post link was copied to your clipboard.", {
        position: "top-right",
      })
    } else if (key === "1") {
      window.open(postURL)
    }
  }

  return (
    <div className="post-template mb-3 post-template-desktop">
      <div className="linkedin-header d-flex justify-content-between align-items-center px-3 py-2">
        <div className="linkedin-header-left d-flex justify-content-center align-items-center">
          <img
            className="rounded-circle linkedin-dp header-profile-user me-1"
            src={user}
            alt="Header Avatar"
          />
          <div>
            <div className="font-weight-semibold font-size-14">
              {linkedInAccount.firstName} {linkedInAccount.lastName}
            </div>
            <div
              className="text-secondary lead font-size-12 position-relative d-none"
              style={{
                width: "90%",
              }}
            >
              Fullstack Software Developer | Golang, ReactJS, MySQL & MomgoDB
            </div>
          </div>
        </div>

        <div>
          <Dropdown
            placement="bottomRight"
            overlay={() => menu(linkedInAccount)}
            trigger={["click"]}
            className="linkedin-option text-secondary"
          >
            <i className="fal fa-ellipsis-h font-size-22"></i>
          </Dropdown>
        </div>
      </div>

      <div className="linkedin-desc px-3 py-2">
        <Linkify>{ReactHtmlParser(content)}</Linkify>
      </div>
      {/* <Image.PreviewGroup>
        {images
          ? images.map(image => {
              return (
                <Image
                  style={{
                    width: "auto",
                    height: "200px",
                  }}
                  src={image.original}
                />
              )
            })
          : null}
      </Image.PreviewGroup> */}
      <div className="linkedin-img mx-3">
        <ImageGallery
          infinite={false}
          showNav={true}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          showIndex={false}
          items={images}
          renderLeftNav={(onClick, disabled) => {
            return <LeftNav onClick={onClick} disabled={disabled} />
          }}
          renderRightNav={(onClick, disabled) => {
            return <RightNav onClick={onClick} disabled={disabled} />
          }}
        />
        {video ? (
          <video
            style={{
              width: "100%",
            }}
            src={video}
            controls
          ></video>
        ) : null}
      </div>

      <div className="linkedin-footer d-flex justify-content-around">
        <div className="d-flex justify-content-center align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            data-supported-dps="24x24"
            fill="#5b7083"
            class="mercado-match"
            width="24"
            height="24"
            focusable="false"
          >
            <path d="M19.46 11l-3.91-3.91a7 7 0 01-1.69-2.74l-.49-1.47A2.76 2.76 0 0010.76 1 2.75 2.75 0 008 3.74v1.12a9.19 9.19 0 00.46 2.85L8.89 9H4.12A2.12 2.12 0 002 11.12a2.16 2.16 0 00.92 1.76A2.11 2.11 0 002 14.62a2.14 2.14 0 001.28 2 2 2 0 00-.28 1 2.12 2.12 0 002 2.12v.14A2.12 2.12 0 007.12 22h7.49a8.08 8.08 0 003.58-.84l.31-.16H21V11zM19 19h-1l-.73.37a6.14 6.14 0 01-2.69.63H7.72a1 1 0 01-1-.72l-.25-.87-.85-.41A1 1 0 015 17l.17-1-.76-.74A1 1 0 014.27 14l.66-1.09-.73-1.1a.49.49 0 01.08-.7.48.48 0 01.34-.11h7.05l-1.31-3.92A7 7 0 0110 4.86V3.75a.77.77 0 01.75-.75.75.75 0 01.71.51L12 5a9 9 0 002.13 3.5l4.5 4.5H19z"></path>
          </svg>
          <div className="ms-1 text-secondary">Like</div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            data-supported-dps="24x24"
            fill="#5b7083"
            class="mercado-match"
            width="24"
            height="24"
            focusable="false"
          >
            <path d="M7 9h10v1H7zm0 4h7v-1H7zm16-2a6.78 6.78 0 01-2.84 5.61L12 22v-4H8A7 7 0 018 4h8a7 7 0 017 7zm-2 0a5 5 0 00-5-5H8a5 5 0 000 10h6v2.28L19 15a4.79 4.79 0 002-4z"></path>
          </svg>
          <div className="ms-1 text-secondary">Comment</div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            data-supported-dps="24x24"
            fill="#5b7083"
            class="mercado-match"
            width="24"
            height="24"
            focusable="false"
          >
            <path d="M23 12l-4.61 7H16l4-6H8a3.92 3.92 0 00-4 3.84V17a4 4 0 00.19 1.24L5.12 21H3l-.73-2.22A6.4 6.4 0 012 16.94 6 6 0 018 11h12l-4-6h2.39z"></path>
          </svg>
          <div className="ms-1 text-secondary">Share</div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            data-supported-dps="24x24"
            fill="#5b7083"
            class="mercado-match"
            width="24"
            height="24"
            focusable="false"
          >
            <path d="M21 3L0 10l7.66 4.26L16 8l-6.26 8.34L14 24l7-21z"></path>
          </svg>
          <div className="ms-1 text-secondary">Send</div>
        </div>
      </div>
    </div>
  )
}

export default LinkedInPreview
