import React, { useState, useEffect } from "react"
import { Drawer, Timeline } from "antd"
import moment from "moment"
import Tooltip from "@material-ui/core/Tooltip"
import { convertFromRaw, ContentState } from "draft-js"
import ReactHtmlParser from "react-html-parser"
import { stateToHTML } from "draft-js-export-html"

const Activity = ({
  showActivity,
  handleClose,
  auditLogs,
  auditNomenclature,
}) => {
  //comment parser
  function rawDataParser(rawData) {
    let fromRawData
    try {
      let parsedData = JSON.parse(rawData)
      fromRawData = convertFromRaw(parsedData)
    } catch (e) {
      fromRawData = ContentState.createFromText(rawData)
    }
    return ReactHtmlParser(stateToHTML(fromRawData))
  }

  function getAuditLog(log) {
    if (
      log &&
      log.auditType &&
      auditNomenclature !== null &&
      auditNomenclature !== undefined
    ) {
      const audit =
        log.auditType !== -1 ? auditNomenclature[log.auditType]?.audit : ""
      let logStr = ""
      if (log.auditType === -1) {
        logStr = <span>{log.customText}</span>
      } else if (log.auditType === 7 || log.auditType === 9) {
        logStr = (
          <span>
            {audit}{" "}
            <i className="font-weight-semibold">{rawDataParser(log.from)}</i>
          </span>
        )
      } else if (log.from && log.from !== "") {
        logStr = (
          <span>
            {audit} from{" "}
            <i className="text-muted">
              <q>
                <strike>{log.from}</strike>
              </q>
            </i>{" "}
            to{" "}
            <q>
              <i className="font-weight-semibold">{log.to}</i>.
            </q>
          </span>
        )
      } else if (
        log.auditType !== 1 &&
        log.auditType !== 7 &&
        log.auditType !== 9
      ) {
        logStr = (
          <span>
            {audit} to <i className="font-weight-semibold">"{log.to}"</i>.
          </span>
        )
      } else {
        logStr = <span>{audit}.</span>
      }

      // if (log.from === "" && log.to === "") {
      //   logStr = audit
      // } else {
      //   logStr = `${audit} from ${log.from === "" ? "Empty" : log.from} to ${
      //     log.to
      //   }`
      // }
      return logStr
    }
    return null
  }

  return (
    <Drawer
      title="Activity"
      placement="right"
      closable={true}
      width="100%"
      onClose={handleClose}
      visible={showActivity}
      getContainer={false}
      style={{
        position: "absolute",
      }}
      className="shadow-lg"
    >
      <Timeline>
        {auditLogs
          ? auditLogs.map(log => {
              return (
                <Timeline.Item key={log.id} color="grey">
                  <div className="d-flex justify-content-between align-items-start">
                    <div
                      style={{
                        width: "70%",
                      }}
                      className="d-flex justify-content-start align-items-start"
                    >
                      <div
                        className="avatar-xs me-2"
                        style={{
                          minWidth: "32px",
                          minWidth: "32px",
                        }}
                      >
                        <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-18">
                          {log.user?.fName ? log.user?.fName.charAt(0) : "?"}
                        </span>
                      </div>
                      <div>
                        <span className="me-2">
                          <strong className="me-1">{log.user?.fName}</strong>
                          {getAuditLog(log)}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Tooltip
                        enterDelay={300}
                        arrow
                        placement="top"
                        title={`${moment
                          .utc(log.timeStamp)
                          .format("D MMM, YYYY")} at ${moment
                          .utc(log.timeStamp)
                          .format("h:mm a")}`}
                      >
                        <small>{moment.utc(log.timeStamp).fromNow()}</small>
                      </Tooltip>
                    </div>
                  </div>
                </Timeline.Item>
              )
            })
          : null}
      </Timeline>
    </Drawer>
  )
}

export default Activity
