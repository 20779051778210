import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import { Form, Input, Select, Dropdown, Collapse, Divider } from "antd"

const { Option } = Select
const { Panel } = Collapse

import toast, { Toaster } from "react-hot-toast"

import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  GET_TASK_DETAILS,
  GET_CUSTOM_FIELDS_BY_WORKSPACE_ID,
  GET_TABLE_DETAILS,
} from "../../../graphql/queries"
import Preview from "./preview"
import WidgetFilter from "./widgetFilter"
import NewMetricModal from "./newMetricModal"
import { filter } from "lodash"
import SettingModal from "./allCharts/highcharts/settingModal"

const NewWidgetModal = ({
  showModal,
  handleCloseModal,
  dashboardID,
  editMode,
  onSave,
  onUpdate,
  data,
}) => {
  const visualizationOptions = [
    {
      source: "https://img.icons8.com/office/80/000000/pie-chart.png",
      value: "Pie",
    },
    {
      source: "https://img.icons8.com/color/96/000000/doughnut-chart.png",
      value: "Donut",
    },
    {
      source: "https://img.icons8.com/color/96/000000/bar-chart--v1.png",
      value: "Bar",
    },
    {
      source: "https://img.icons8.com/color/96/000000/select-column.png",
      value: "Column",
    },
    {
      source: "https://img.icons8.com/office/40/000000/counter.png",
      value: "Counter",
    },
    {
      source:
        "https://img.icons8.com/external-flatart-icons-flat-flatarticons/64/000000/external-text-device-and-development-flatart-icons-flat-flatarticons.png",
      value: "Text",
    },
    {
      source: "https://img.icons8.com/color/64/000000/data-sheet.png",
      value: "Table",
    },
  ]

  const params = useParams()
  const [columns, setColumns] = useState([{ id: "", fieldName: "" }])
  const [filters, setFilters] = useState(undefined)
  const [visualData, setVisualData] = useState(undefined)
  const [visualDataCpy, setVisualDataCpy] = useState(undefined)
  const [filteredVisualData, setFilteredVisualData] = useState(undefined)
  const [showMetricModal, setShowMetricModal] = useState(false)
  const [colors, setColors] = useState(null)
  const [selectedData, setSelectedData] = useState(null)
  const [showSettingModal, setShowSettingModal] = useState(false)
  const [previewLoading, setPreviewLoading] = useState(false)

  const [columnClickIndex, setColumnClickIndex] = useState(undefined)

  const [widgetName, setWidgetName] = useState(undefined)
  const [dataSource, setDataSource] = useState(undefined)
  const [visualName, setVisualName] = useState(undefined)
  const [isTextWidget, setIsTextWidget] = useState(false)
  const [text, setText] = useState(null) // for text widget

  const [isLineChart, setIsLineChart] = useState(false)
  const [tableData, setTableData] = useState(null)

  const handleTextChange = txt => {
    // for text widget
    setText(txt)
  }

  useEffect(() => {
    if (!showModal) {
      return
    }

    if (!editMode) {
      setVisualData(undefined)
      setColumns([{ id: "", fieldName: "" }])
      setColumnClickIndex(undefined)
      setWidgetName(undefined)
      setDataSource(undefined)
      setVisualName(undefined)
      setFilters(undefined)
      setTableData(null)
    } else {
      setFilters(data.filters)
      setWidgetName(data.widgetName)
      setDataSource(data.dataSource)
      setVisualName(data.visualName)
      setVisualDataCpy(data.datas)
      setTableData(data.tableData)

      let newVisualData = []
      if (data.filters && data.filters.length) {
        data.filters?.forEach(f => {
          if (f.type === "0") {
            // Contains
            data.datas.forEach(data => {
              if (data.fieldID === f.fieldID && f.values.includes(data.index)) {
                newVisualData.push(data)
              }
            })
          } else {
            // Not Contains
            data.datas.forEach(data => {
              if (
                data.fieldID === f.fieldID &&
                !f.values.includes(data.index)
              ) {
                newVisualData.push(data)
              }
            })
          }
        })
        data.datas.forEach(data => {
          let index = data.filters?.findIndex(f => f.fieldID === data.fieldID)
          if (index === -1) {
            newVisualData.push(data)
          }
        })
        console.log("newVisualData", newVisualData)
        setVisualData(newVisualData)
      } else {
        setVisualData(data.datas)
      }

      /* let newColumns = []
      let fieldMap = {}
    
      data.datas.forEach(ele => {
        if (!fieldMap[ele.name]) {
          newColumns.push({
            fieldName: ele.name,
            count: ele.count,
            index: ele.index,
          })
          fieldMap[ele.name] = ele
        } else {
          fieldMap[ele.name] = ele
        }
      }) */
      console.log("data.fields", data.fields)
      if (data.fields) {
        setColumns(data.fields)
      }
    }
  }, [showModal])

  const [getTaskDetails] = useLazyQuery(GET_TASK_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getTaskDetails) {
        if (visualData && visualDataCpy && columns.length > 1) {
          /* setVisualData([...data.getTaskDetails, ...visualData])
          setVisualDataCpy([...data.getTaskDetails, ...visualData]) */

          let vData = [...visualData]
          data.getTaskDetails.forEach(taskDetail => {
            columns?.forEach(col => {
              if (col.id === taskDetail.fieldID) {
                vData.push(taskDetail)
              }
            })
          })
          setVisualData(vData)
          setVisualDataCpy(vData)
        } else {
          setVisualData(data.getTaskDetails)
          setVisualDataCpy(data.getTaskDetails)
        }
      }
      console.log("data----->", data)
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [getTableDetails] = useLazyQuery(GET_TABLE_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      console.log("data", data)
      if (data && data.getTableDetails) {
        if (tableData && tableData.length) {
          setTableData([...tableData, data.getTableDetails])
        } else {
          setTableData([data.getTableDetails])
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const handleWidgetNameChange = value => {
    setWidgetName(value)
  }

  const handleVisualNameChange = value => {
    if (visualName === "Table") {
      setColumns([{ id: "", fieldName: "" }])
      setTableData(null)
    }
    setVisualName(value)
  }

  useEffect(() => {
    if (visualName === "Text") {
      setIsTextWidget(true)
    } else {
      setIsTextWidget(false)
    }
    if (visualName === "Line") {
      setIsLineChart(true)
    } else {
      setIsLineChart(false)
    }
  }, [visualName])

  useEffect(() => {
    let newColors = []
    if (visualDataCpy) {
      visualDataCpy.forEach(ele => {
        newColors.push({
          id: `${ele.fieldID}_${ele.index}`,
          color: ele.color,
        })
      })
      setColors(newColors)
    }
  }, [visualDataCpy])

  // handleAddColumn - Check if the last column is selected if so then add else dont add and show message
  const handleAddColumn = () => {
    const columnsLength = columns.length - 1
    let lastColumn = columns[columnsLength]
    if (lastColumn.fieldName !== "") {
      // Add
      let newColumn = {
        id: "",
        fieldName: "",
      }
      let newColumns = [...columns, newColumn]
      setColumns(newColumns)
    } else {
      // Show message
      toast.error(
        t => (
          <span>
            The fields should not be empty.
            <br />
            Please use the empty field before adding a new one.
          </span>
        ),
        {
          position: "top-right",
        }
      )
    }
  }

  // handleRemoveColumn - Remove the column/field - Remove when there are more than one column/field
  const handleRemoveColumn = (column, index) => {
    // Check if filter exits for this coulmn - if so show error - "Remove the filter associated with this column and then delete the column"
    let isFilterPresent = false
    if (filters && filters.length) {
      filters.forEach(filter => {
        if (column.id === filter.fieldID) {
          isFilterPresent = true
        }
      })
    }

    if (isFilterPresent) {
      toast.error(
        <span>
          Please remove the filter associated with {column.fieldName}(
          {column.spaceName}) and then remove this column.
        </span>,
        {
          position: "top-right",
        }
      )
    } else if (columns.length > 1) {
      let newColumns = [...columns]
      newColumns.splice(index, 1)
      setColumns(newColumns)

      let vData = []
      visualDataCpy?.forEach(ele => {
        if (ele.fieldID !== column.id) {
          vData.push(ele)
        }
      })
      setVisualData(vData)
      setVisualDataCpy(vData)
      if (filters) {
        let filterIndex = filters.findIndex(f => f.fieldID === column.id)
        let newFilters = [...filters]

        if (filterIndex > -1) {
          newFilters.splice(filterIndex, 1)
          setFilters(newFilters)
        }
      }
      if (visualName === "Table") {
        let tIndex = tableData.findIndex(ele => ele.fieldID === column.id)
        let newTableData = [...tableData]
        if (tIndex > -1) {
          newTableData.splice(tIndex, 1)
          setTableData(newTableData)
        }
      }
    } else {
      toast.error(<span>There should be atleast one field.</span>, {
        position: "top-right",
      })
    }
  }

  // handleClickColumn -  Opens the metric modal
  const handleClickColumn = index => {
    setColumnClickIndex(index)
    setShowMetricModal(true)
  }

  const handleXAxisClick = () => {
    setShowMetricModal(true)
  }

  const handleAddMetric = field => {
    if (field) {
      let newCols = [...columns]
      let isExists = false
      console.log("newCols", newCols)
      console.log("field", field)
      isExists = newCols.some(ele => {
        if (ele.id === field.id) {
          return true
        }
      })
      if (isExists) {
        toast.error(
          t => (
            <span>
              Metric is already selected. Please select a different metric.
            </span>
          ),
          {
            position: "top-right",
            duration: 2000,
          }
        )
        return
      }
      newCols[columnClickIndex] = field
      console.log("newCols ---->", newCols)
      setColumns(newCols)
      if (visualName === "Table") {
        getTableDetails({
          variables: {
            fieldID: field.id,
          },
        })
      } else {
        getTaskDetails({
          variables: {
            workspaceID: params.workspaceID,
            fieldID: field.id,
          },
        })
      }
    }

    setShowMetricModal(false)
  }

  const isColumnsValid = () => {
    let valid = true
    if (columns && columns.length) {
      columns.forEach(col => {
        if (col.id === "") {
          valid = false
        }
      })
    } else {
      valid = false
    }

    return valid
  }

  function onSubmit(values) {
    if (!isColumnsValid()) {
      toast.error(
        t => (
          <span>
            Column field should not be empty. Please select a field or remove
            the column.
          </span>
        ),
        {
          position: "top-right",
        }
      )
      return
    }

    if ((columns && columns[0].id !== "") || isTextWidget) {
      let fieldIDs = []

      visualData?.forEach((ele, index) => {
        if (!fieldIDs.includes(ele.fieldID)) {
          fieldIDs.push(ele.fieldID)
        }
      })

      if (values.visualName === "Table") {
        columns?.forEach(col => {
          fieldIDs.push(col.id)
        })
      }

      let input = {
        dashboardID,
        widgetName: values.widgetName,
        dataSource: values.dataSource,
        visualName: values.visualName,
        text: text ? text : "",
        fieldIDs,
        colors: colors,
      }
      if (filters) {
        let newFilters = []
        filters.forEach(f => {
          delete f.__typename
          newFilters.push(f)
        })
        input.filters = newFilters
      }

      if (editMode) {
        onUpdate(data.id, input)
      } else {
        onSave(input)
      }
    } else {
      toast.error(t => <span>Please choose atleat one field.</span>, {
        position: "top-right",
      })
    }
  }

  const handleAddNewFilter = () => {
    let newFilter = { fieldID: "", type: "", value: "" }
    if (filters) {
      setFilters([...filters, newFilter])
    } else {
      setFilters([newFilter])
    }
  }

  const handleRemoveFilter = (filter, index) => {
    if (filters.length > 0) {
      let newFilters = [...filters]
      if (index > -1) {
        newFilters.splice(index, 1)
        setFilters(newFilters)
        filterAlgorithm(newFilters)
      }
    }
  }

  useEffect(() => {
    if (filters) {
      filterAlgorithm(filters)
    }
  }, [columns, filters])

  const filterAlgorithm = updatedFilters => {
    let newVisualData = []

    updatedFilters.forEach(f => {
      if (f.type === "0") {
        // Contains
        visualDataCpy.forEach(data => {
          if (data.fieldID === f.fieldID && f.values.includes(data.index)) {
            newVisualData.push(data)
          }
        })
      } else {
        // Not contains
        visualDataCpy.forEach(data => {
          if (data.fieldID === f.fieldID && !f.values.includes(data.index)) {
            newVisualData.push(data)
          }
        })
      }
    })
    visualDataCpy.forEach(data => {
      let index = updatedFilters.findIndex(f => f.fieldID === data.fieldID)
      if (index === -1) {
        newVisualData.push(data)
      }
    })

    setVisualData(newVisualData)
  }

  const handleUpdateFilter = (fieldID, type, values) => {
    if (!fieldID || !type || !values) {
      return
    }
    let index = filters.findIndex(filter => filter.fieldID === fieldID)
    let filter = {
      fieldID,
      type,
      values,
    }
    let newFilters = filters.map(({ __typename, ...items }) => items)
    if (index > -1) {
      newFilters[index] = filter
    } else {
      newFilters[filters.length - 1] = filter
    }
    let newVisualData = []

    newFilters.forEach(f => {
      if (f.type === "0") {
        // Contains
        visualDataCpy.forEach(data => {
          if (data.fieldID === f.fieldID && f.values.includes(data.index)) {
            newVisualData.push(data)
          }
        })
      } else {
        // Not contains
        visualDataCpy.forEach(data => {
          if (data.fieldID === f.fieldID && !f.values.includes(data.index)) {
            newVisualData.push(data)
          }
        })
      }
    })
    visualDataCpy.forEach(data => {
      let index = newFilters.findIndex(f => f.fieldID === data.fieldID)
      if (index === -1) {
        newVisualData.push(data)
      }
    })

    setVisualData(newVisualData)
    setFilters([...newFilters])
  }

  const handleChangeDatas = (selectedData, color) => {
    let newVisualData = []
    visualData.forEach(data => {
      let newData = { ...data }
      delete newData.__typename
      if (
        data.fieldID === selectedData.fieldID &&
        data.name === selectedData.name
      ) {
        newData.color = color
      }
      newVisualData.push(newData)
    })
    setVisualData(newVisualData)

    let newVisualDataCpy = []
    visualDataCpy.forEach(ele => {
      if (
        ele.fieldID === selectedData.fieldID &&
        ele.name === selectedData.name
      ) {
        ele.color = color
      }
      newVisualDataCpy.push(ele)
    })
    setVisualDataCpy(newVisualDataCpy)
  }

  useEffect(() => {
    if (selectedData !== null) {
      setShowSettingModal(true)
    }
  }, [selectedData])

  const handleChangeSelectedData = data => {
    setSelectedData({ ...data })
  }

  return (
    <Modal
      className="modal-fullscreen widget-modal"
      size="xl"
      isOpen={showModal}
    >
      <ModalHeader tag="h4" toggle={handleCloseModal}>
        Create a new widget
      </ModalHeader>
      <Form
        onFinish={onSubmit}
        initialValues={{
          ["widgetName"]: widgetName && widgetName !== "" ? widgetName : null,
          ["dataSource"]: dataSource && dataSource !== "" ? dataSource : null,
          ["visualName"]: visualName && visualName !== "" ? visualName : null,
        }}
      >
        <ModalBody className="custom-modal-body">
          <div className="form-and-preview">
            <div className="left-form">
              <label className="text-dark">
                Widget Name <span className="text-danger">*</span>
              </label>
              <Form.Item
                name="widgetName"
                rules={[
                  { required: true, message: "Please enter widget name" },
                ]}
              >
                <Input
                  // value={widgetName}
                  onChange={event => {
                    handleWidgetNameChange(event.target.value)
                  }}
                  className="rounded-3"
                  size="middle"
                  placeholder="Enter the name for this widget"
                />
              </Form.Item>
              <label className="text-dark">
                Data source <span className="text-danger">*</span>
              </label>
              <Form.Item
                name="dataSource"
                rules={[
                  { required: true, message: "Please enter widget name" },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select a data source"
                  style={{
                    height: "32px",
                    border: "1px solid #d9d9d9",
                    borderRadius: "6px",
                    width: "100%",
                  }}
                  value={dataSource}
                  onChange={value => setDataSource(value)}
                  bordered={false}
                  dropdownStyle={{ borderRadius: "6px" }}
                  optionFilterProp="children"
                >
                  <Option value="Task Analytics">Task Analytics</Option>
                </Select>
              </Form.Item>
              <Collapse
                ghost
                expandIcon={({ isActive }) => (
                  <i
                    className={`fas fa-caret-right ms-1 font-size-14 ${
                      isActive ? "bx-rotate-90" : ""
                    }`}
                  />
                )}
                defaultActiveKey={[
                  "1",
                  "2",
                  filters && filters.length ? "3" : null,
                ]}
              >
                <Panel
                  header={
                    <div className="d-flex justify-content-start align-items-center w-100">
                      <div
                        className="font-size-15 font-weight-semibold"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        Select a visualization for your widget
                      </div>
                      <div className="h-line"></div>
                    </div>
                  }
                  key="1"
                  className="site-collapse-custom-panel my-2"
                >
                  <Form.Item
                    name="visualName"
                    rules={[
                      {
                        required: true,
                        message: "Please select a visualization",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select a visualization"
                      style={{
                        height: "34px",
                        border: "1px solid #d9d9d9",
                        borderRadius: "6px",
                        width: "100%",
                      }}
                      value={visualName}
                      onChange={value => handleVisualNameChange(value)}
                      bordered={false}
                      dropdownStyle={{ borderRadius: "6px" }}
                    >
                      {visualizationOptions.map((option, index) => {
                        return (
                          <Option value={option.value} key={index}>
                            <div>
                              <img
                                src={option.source}
                                width="22"
                                className="me-2"
                                alt=""
                              />
                              {option.value}
                            </div>
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
                {isTextWidget ? null : (
                  <>
                    <Panel
                      header={
                        <div className="d-flex justify-content-start align-items-center w-100">
                          <div
                            className="font-size-15 font-weight-semibold"
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            What would you like to plot on your widget?
                          </div>
                          <div className="h-line"></div>
                        </div>
                      }
                      key="2"
                      className="site-collapse-custom-panel my-2"
                    >
                      <div>
                        <div>
                          <div className="font-size-14 text-secondary mt-1">
                            Select Metrics/Dimensions
                          </div>
                        </div>

                        {/* For Line chart */}
                        <div className="mt-2" hidden={visualName !== "Line"}>
                          <div className="d-flex align-items-center mb-2">
                            <div
                              style={{
                                width: "100px",
                              }}
                            >
                              X-Axis
                            </div>
                            <Input
                              onClick={() => handleClickColumn(0)}
                              className="rounded-1 me-2"
                              type="text"
                              style={{
                                padding: "0.2rem 0.5rem",
                              }}
                              value={columns && columns[0]?.fieldName}
                              placeholder="Field"
                              size="small"
                            />
                          </div>
                        </div>
                        <div className="mt-2" hidden={visualName !== "Line"}>
                          <div className="d-flex align-items-center mb-2">
                            <div
                              style={{
                                width: "100px",
                              }}
                            >
                              Y-Axis
                            </div>
                            <Input
                              onClick={() => handleClickColumn(1)}
                              className="rounded-1 me-2"
                              type="text"
                              style={{
                                padding: "0.2rem 0.5rem",
                              }}
                              value={columns && columns[1]?.fieldName}
                              placeholder="Field"
                              size="small"
                            />
                          </div>
                        </div>
                        <div className="mt-2" hidden={visualName !== "Line"}>
                          <div className="d-flex align-items-center mb-2">
                            <div
                              style={{
                                width: "100px",
                              }}
                            >
                              Series
                            </div>
                            <Input
                              onClick={() => handleClickColumn(2)}
                              className="rounded-1 me-2"
                              type="text"
                              style={{
                                padding: "0.2rem 0.5rem",
                              }}
                              value={columns && columns[2]?.fieldName}
                              placeholder="Field"
                              size="small"
                            />
                          </div>
                        </div>

                        <div className="mt-2" hidden={visualName === "Line"}>
                          {columns
                            ? columns.map((column, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mb-2"
                                >
                                  <div
                                    style={{
                                      width: "100px",
                                    }}
                                  >
                                    Column {index + 1}
                                  </div>
                                  <Input
                                    onClick={() => handleClickColumn(index)}
                                    onChange={() => {}}
                                    className="rounded-1 me-2"
                                    type="text"
                                    style={{
                                      padding: "0.2rem 0.5rem",
                                    }}
                                    value={
                                      column.fieldName +
                                      (column.spaceName
                                        ? ` (${column.spaceName})`
                                        : "")
                                    }
                                    placeholder="Field"
                                    size="small"
                                  />
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleRemoveColumn(column, index)
                                    }
                                    className="rounded-3"
                                  >
                                    <i className="fal fa-times font-size-18 text-secondary ms-2"></i>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                        <div
                          onClick={handleAddColumn}
                          className="mt-2 btn rounded-2 btn-sm btn-info"
                        >
                          Add Field
                        </div>
                      </div>
                    </Panel>
                    {/* NOTE Filters */}
                    <Panel
                      header={
                        <div className="d-flex justify-content-start align-items-center w-100">
                          <div className="font-weight-semibold">Filters</div>
                          <div className="h-line"></div>
                        </div>
                      }
                      key="3"
                      className="site-collapse-custom-panel my-2"
                    >
                      {filters
                        ? filters.map((filter, i) => {
                            return (
                              <div
                                key={i}
                                className="mb-2 d-flex justify-content-between align-items-center"
                              >
                                <div className="w-100">
                                  <WidgetFilter
                                    columns={columns}
                                    visualData={visualDataCpy}
                                    filteredVisualData={filteredVisualData}
                                    handleUpdateFilter={handleUpdateFilter}
                                    filter={filter}
                                    filters={filters}
                                  />
                                </div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleRemoveFilter(filter, i)}
                                  className="rounded-3"
                                >
                                  <i className="fal fa-times font-size-18 text-secondary ms-2"></i>
                                </div>
                              </div>
                            )
                          })
                        : null}
                      <div
                        className="mt-2 btn btn-primary btn-sm rounded-3"
                        onClick={handleAddNewFilter}
                      >
                        <i className="fal fa-plus me-1"></i> Add New Filter
                      </div>
                    </Panel>
                  </>
                )}
              </Collapse>
            </div>
            <div className="right-preview">
              <Preview
                previewLoading={previewLoading}
                widgetName={widgetName}
                visualName={visualName}
                visualData={visualData}
                handleWidgetNameChange={handleWidgetNameChange}
                handleTextChange={handleTextChange}
                editMode={editMode}
                textData={data?.text}
                handleChangeSelectedData={handleChangeSelectedData}
                tableData={tableData}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <div
            onClick={handleCloseModal}
            className="btn btn-outline-secondary rounded-3"
          >
            Cancel
          </div>
          <button className="btn rounded-3 btn-primary">
            {editMode ? "Update" : "Create"}
          </button>
        </ModalFooter>
      </Form>

      <NewMetricModal
        workspaceID={params.workspaceID}
        dataSource={dataSource}
        selectedColumns={columns}
        showModal={showMetricModal}
        handleAddMetric={handleAddMetric}
        handleClose={() => setShowMetricModal(false)}
        visualName={visualName}
      />
      <SettingModal
        handleChangeDatas={handleChangeDatas}
        datas={visualData}
        selectedData={selectedData}
        showModal={showSettingModal}
        handleCloseModal={() => setShowSettingModal(false)}
        handleChangeSelectedData={handleChangeSelectedData}
      />
    </Modal>
  )
}

export default NewWidgetModal
