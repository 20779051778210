import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Container } from "reactstrap"
import { Card, CardBody, Col, Row, Badge, Spinner } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import FieldModal from "./components/customFieldModal"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  GET_ALL_CUSTOM_FIELDS_BY_TENANT_ID,
  GET_ALL_SPACES_BY_TENANT_ID,
  WORKSPACES,
} from "../../graphql/queries"

import "./customField.css"
import {
  ADD_CUSTOM_FIELD,
  DELETE_CUSTOM_FIELD,
  EDIT_CUSTOM_FIELD,
} from "../../graphql/mutation"
import { Switch } from "antd"
import ConfirmDeleteModal from "../../components/Dialog/confirmDeleteModal"

const CustomFields = () => {
  const [customFields, setCustomFields] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [customField, setCustomField] = useState(undefined)
  const [workspaces, setWorkspaces] = useState(null)
  const [spaces, setSpaces] = useState(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const {} = useQuery(GET_ALL_CUSTOM_FIELDS_BY_TENANT_ID, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      console.log("data", data)
      if (data && data.getAllCustomFieldsByTenantID) {
        setCustomFields(data.getAllCustomFieldsByTenantID)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const {} = useQuery(GET_ALL_SPACES_BY_TENANT_ID, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      console.log("data", data)
      if (data && data.getAllSpacesByTenantID) {
        setSpaces(data.getAllSpacesByTenantID)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const {} = useQuery(WORKSPACES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      console.log("data", data)
      if (data && data.workspaces) {
        setWorkspaces(data.workspaces)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [addCustomField] = useMutation(ADD_CUSTOM_FIELD, {
    onCompleted: data => {
      if (data && data.addCustomField) {
        if (customFields && customFields.length) {
          setCustomFields([data.addCustomField, ...customFields])
        } else {
          setCustomFields([data.addCustomField])
        }
      }
      setShowModal(false)
    },
    onError: err => {
      console.log("err", err)
      setShowModal(false)
    },
  })

  const [editCustomField] = useMutation(EDIT_CUSTOM_FIELD, {
    onCompleted: data => {
      if (data && data.editCustomField) {
        let index = customFields.findIndex(
          ele => ele.id === data.editCustomField.id
        )
        setCustomFields([
          ...customFields.slice(0, index),
          { ...data.editCustomField },
          ...customFields.slice(index + 1),
        ])
      }
      setShowModal(false)
    },
    onError: err => {
      console.log("err", err)
      setShowModal(false)
    },
  })

  const [deleteCustomField] = useMutation(DELETE_CUSTOM_FIELD, {
    onCompleted: data => {
      if (data && data.deleteCustomField) {
        let index = customFields.findIndex(ele => ele.id === customField.id)
        if (index > -1) {
          let newCustomFields = [...customFields]
          newCustomFields.splice(index, 1)
          setCustomFields(newCustomFields)
        }
      }
      setShowDeleteDialog(false)
      setCustomField(null)
    },
    onError: err => {
      console.log("err", err)
      setShowDeleteDialog(false)
      setCustomField(null)
    },
  })

  const handleCreateCustomField = () => {
    setEditMode(false)
    setCustomField(undefined)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleUpdateCustomField = cf => {
    setEditMode(true)
    setCustomField({ ...cf })
    setShowModal(true)
  }
  const handleDeleteCustomField = cf => {
    setCustomField(cf)
    setShowDeleteDialog(true)
  }

  const { SearchBar } = Search

  const onSave = input => {
    addCustomField({
      variables: {
        input,
      },
    })
  }

  const onUpdate = (fieldID, input) => {
    editCustomField({
      variables: {
        fieldID,
        input,
      },
    })
  }

  const onDelete = () => {
    if (customField) {
      deleteCustomField({
        variables: {
          fieldID: customField.id,
          fromSettings:
            customField.fromSettings !== undefined ||
            customField.fromSettings !== null
              ? customField.fromSettings
              : false,
        },
      })
    }
  }

  const getSpaceNameByIDs = spaceIDs => {
    let text = ""
    console.log(spaceIDs, spaces)
    if (spaceIDs) {
      spaces?.forEach(space => {
        if (spaceIDs.includes(space.id)) {
          text += `${space.name} `
        }
      })
    }
    return text
  }

  const pageOptions = {
    sizePerPage: 10,
    totalSize: customFields.length,
    custom: true,
  }
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const getVisibilityName = cf => {
    let names = []
    if (cf.fromSettings === true) {
      if (cf.isAllWorkspaces) {
        names.push("All Workspaces")
      } else if (cf.isAllSpaces) {
        names.push("All Spaces")
      } else if (cf.workspaces && cf.workspaces.length) {
        cf.workspaces.forEach(w => names.push(w.name))
      } else if (cf.spaces && cf.spaces.length) {
        cf.spaces.forEach(s => names.push(s.name))
      }
    } else {
      if (cf.space) {
        names.push(cf.space?.name)
      } else if (cf.workspace) {
        names.push(cf.workspace?.name)
      }
    }
    return names
  }

  const getLevelName = cf => {
    let levelName = ""
    if (cf.fromSettings === true) {
      if (cf.isAllWorkspaces) {
        levelName = "Workspaces"
      } else if (cf.isAllSpaces) {
        levelName = "Spaces"
      } else if (cf.workspaces && cf.workspaces.length) {
        levelName = "Workspaces"
      } else if (cf.spaces && cf.spaces.length) {
        levelName = "Spaces"
      }
    } else {
      if (cf.space) {
        levelName = "Spaces"
      } else if (cf.workspace) {
        levelName = "Workspaces"
      }
    }

    return levelName
  }

  const customFieldsColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      formatter: (_, customField) => <>{customField.id}</>,
    },
    {
      text: "Active",
      dataField: "active",
      sort: true,
      formatter: (_, customField) =>
        customField?.active === false ? (
          <Switch size="small" checked={false} />
        ) : (
          <Switch size="small" checked={true} />
        ),
    },
    {
      text: "Name",
      dataField: "fieldName",
      sort: true,
    },
    {
      text: "Field Type",
      dataField: "fieldType",
      sort: true,
    },
    {
      text: "Description",
      dataField: "description",
      sort: true,
      formatter: (_, customField) => (
        <div
          style={{
            width: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {customField.description}
        </div>
      ),
    },
    {
      text: "Visibility",
      dataField: "visibility",
      sort: true,
      formatter: (_, customField) => {
        let visibilities = []
        let names = getVisibilityName(customField)
        let levelName = getLevelName(customField)
        if (names && names.length) {
          names.forEach(name => {
            visibilities.push(
              <Badge pill className="badge-soft-primary me-1 font-size-12">
                {name}
              </Badge>
            )
          })
        }
        return (
          <span>
            {" "}
            {levelName}: {visibilities}
          </span>
        )
      },
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, customField) => (
        <div className="d-flex gap-3">
          <div
            className="text-success"
            onClick={() => handleUpdateCustomField(customField)}
            style={{ cursor: "pointer" }}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
          </div>
          <div
            className="text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteCustomField(customField)}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
          </div>
        </div>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          overflow: "scroll",
        }}
      >
        <MetaTags>
          <title>Custom Fields | Concize </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            left="Custom Fields"
            url="/settings"
            urlTitle="Settings"
            right="Custom Fields"
          />

          <Row>
            <Col lg="12">
              <Card style={{ position: "relative" }}>
                <CardBody>
                  <Card
                    style={{
                      position: "absolute",
                      zIndex: "2",
                      left: `calc(50% - 100px)`,
                      top: "30%",
                      width: "150px",
                    }}
                    className="shadow-lg text-center"
                  >
                    <CardBody hidden={!loading}>
                      <p>Please wait</p>
                      <Spinner color="primary" hidden={!loading} />
                    </CardBody>
                  </Card>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    columns={customFieldsColumns}
                    data={customFields}
                    keyField="id"
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={customFields}
                        columns={customFieldsColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <div className="d-flex justify-content-between align-items-start">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                              <div
                                onClick={handleCreateCustomField}
                                className="btn d-block btn-primary rounded-3"
                              >
                                <i className="fas fa-plus-circle me-2" />
                                Create Custom Field
                              </div>
                            </div>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    keyField="id"
                                    // selectRow={selectRow}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                    responsive
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <FieldModal
        showModal={showModal}
        data={customField}
        editMode={editMode}
        handleClose={handleCloseModal}
        workspaces={workspaces}
        spaces={spaces}
        onSave={onSave}
        onUpdate={onUpdate}
      />
      <ConfirmDeleteModal
        handleClose={() => setShowDeleteDialog(false)}
        showDialog={showDeleteDialog}
        title="Delete Custom Field"
        subText={`Are you sure you want to delete "${customField?.fieldName}" ?`}
        onConfirm={onDelete}
      />
    </React.Fragment>
  )
}

export default CustomFields
