import { gql } from "@apollo/client"

export const TASK_FRAGMENT = gql`
  fragment TaskFragment on Task {
    id
    title
    isSubtask
    parentID
    description
    status
    startDate
    endDate
    assignees {
      id
      user {
        fName
        lName
        isDeleted
      }
    }
    subTasks {
      id
      parentID
      title
      startDate
      endDate
      status
      description
      comments {
        id
        isUserComment
        user {
          fName
          lName
          email
        }
        comment
        createdAt
        updatedAt
      }
    }
    comments {
      id
      isUserComment
      user {
        fName
        lName
        email
      }
      comment
      createdAt
      updatedAt
    }
    audit {
      id
      auditType
      timeStamp
      user {
        id
        fName
        lName
        email
      }
      customText
      from
      to
    }
    customFieldValues {
      custFieldID
      value
    }
    space {
      id
    }
  }
`

export const ADD_AUDIT = gql`
  mutation addAudit($input: AuditInput!) {
    addAudit(input: $input) {
      id
      title
      parentID
      startDate
      endDate
      status
      description
      assignees {
        id
        user {
          fName
          lName
          firebaseID
        }
      }
      subTasks {
        id
        title
        parentID
        startDate
        endDate
        status
        description
        comments {
          id
          isUserComment
          user {
            fName
            lName
            email
          }
          comment
          createdAt
          updatedAt
        }
      }
      comments {
        id
        isUserComment
        user {
          fName
          lName
          email
        }
        comment
        createdAt
        updatedAt
      }
      customFieldValues {
        custFieldID
        value
      }
      audit {
        id
        auditType
        timeStamp
        user {
          fName
        }
        from
        to
      }
    }
  }
`
export const DELETE_AUDIT = gql`
  mutation deleteAudit($auditID: String!, $taskID: String!) {
    deleteAudit(auditID: $auditID, taskID: $taskID) {
      audit
      field
      userID
      id
      taskID
      timeStamp
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation ($input: UserInput!) {
    updateProfile(input: $input) {
      id
      fName
      lName
      email
      mobile
    }
  }
`

export const INSERT_USER = gql`
  mutation ($input: CustomUserInput!) {
    insertUser(input: $input) {
      id
      fName
      lName
      email
      mobile
    }
  }
`

export const REGISTER_SYSTEM_ADMIN = gql`
  mutation ($input: CreateUserInput!) {
    addUser(input: $input) {
      id
      fName
      lName
      email
      role
      mobile
      token
    }
  }
`

export const UPDATE_USER_PROFILE = gql`
  mutation ($input: CustomUserInput!) {
    updateUserProfile(input: $input) {
      fName
      lName
      email
      role
      mobile
      tenant {
        Name
      }
    }
  }
`

// Workspace mutations
export const CREATE_WORKSPACE = gql`
  mutation createWorkspace($input: WorkspaceInput!) {
    createWorkspace(input: $input) {
      id
      name
    }
  }
`

export const DELETE_WORKSPACE = gql`
  mutation deleteWorkspace($id: String!) {
    deleteWorkspace(id: $id) {
      id
      name
    }
  }
`

export const UPDATE_WORKSPACE = gql`
  mutation updateWorkspace($id: String!, $name: String!) {
    updateWorkspace(id: $id, name: $name) {
      id
      name
    }
  }
`

// Task mutations
export const ADD_TASK = gql`
  mutation addTask($input: TaskInput!) {
    addTask(input: $input) {
      id
      title
      parentID
      startDate
      endDate
      status
      description
      assignees {
        id
        user {
          fName
          lName
          firebaseID
        }
      }
      subTasks {
        id
        title
        parentID
        startDate
        endDate
        status
        description
      }
      comments {
        id
        isUserComment
        user {
          fName
          lName
          email
        }
        comment
        createdAt
        updatedAt
      }
      customFieldValues {
        custFieldID
        value
      }
      audit {
        id
        auditType
        timeStamp
        user {
          fName
        }
        from
        to
      }
    }
  }
`

export const ADD_SUB_TASK = gql`
  ${TASK_FRAGMENT}
  mutation addSubTask($input: TaskInput!) {
    addSubTask(input: $input) {
      ...TaskFragment
    }
  }
`

export const DELETE_TASK = gql`
  mutation deleteTask($taskID: String!) {
    deleteTask(taskID: $taskID) {
      id
      title
    }
  }
`

export const UPDATE_TASK = gql`
  ${TASK_FRAGMENT}
  mutation updateTask(
    $input: TaskInput!
    $taskID: String!
    $auditInput: AuditInput!
  ) {
    updateTask(input: $input, taskID: $taskID, auditInput: $auditInput) {
      ...TaskFragment
    }
  }
`

export const UPDATE_SUB_TASK = gql`
  ${TASK_FRAGMENT}
  mutation updateSubTask(
    $input: TaskInput!
    $taskID: String!
    $auditInput: AuditInput!
  ) {
    updateSubTask(input: $input, taskID: $taskID, auditInput: $auditInput) {
      ...TaskFragment
    }
  }
`

export const DELETE_SUB_TASK = gql`
  ${TASK_FRAGMENT}
  mutation deleteSubTask($subTaskID: String!, $parentID: String!) {
    deleteSubTask(subTaskID: $subTaskID, parentID: $parentID) {
      ...TaskFragment
    }
  }
`

export const ADD_COMMENT = gql`
  ${TASK_FRAGMENT}
  mutation addComment($input: CommentInput!) {
    addComment(input: $input) {
      ...TaskFragment
    }
  }
`

export const DELETE_COMMENT = gql`
  ${TASK_FRAGMENT}
  mutation deleteComment(
    $id: String!
    $taskID: String!
    $auditInput: AuditInput!
  ) {
    deleteComment(id: $id, taskID: $taskID, auditInput: $auditInput) {
      ...TaskFragment
    }
  }
`

export const UPDATE_COMMENT = gql`
  ${TASK_FRAGMENT}
  mutation updateComment(
    $id: String!
    $input: CommentInput!
    $auditInput: AuditInput!
  ) {
    updateComment(id: $id, input: $input, auditInput: $auditInput) {
      ...TaskFragment
    }
  }
`

export const CHANGE_SUBTASKS_ORDER = gql`
  ${TASK_FRAGMENT}
  mutation changeSubTasksOrder($subTaskIDs: [String!], $taskID: String!) {
    changeSubTasksOrder(subTaskIDs: $subTaskIDs, taskID: $taskID) {
      ...TaskFragment
    }
  }
`
export const SPACES_FRAGMENT = gql`
  fragment SpacesFragment on Space {
    id
    name
    isFolder
    workspace {
      id
    }
  }
`

export const CREATE_SPACE = gql`
  ${SPACES_FRAGMENT}
  mutation createSpace($input: SpaceInput!) {
    createSpace(input: $input) {
      ...SpacesFragment
      spaces {
        ...SpacesFragment
        spaces {
          ...SpacesFragment
          spaces {
            ...SpacesFragment
            spaces {
              ...SpacesFragment
            }
          }
        }
      }
    }
  }
`

export const UPDATE_SPACE = gql`
  ${SPACES_FRAGMENT}
  mutation updateSpace($id: String!, $input: SpaceInput!) {
    updateSpace(id: $id, input: $input) {
      ...SpacesFragment
      spaces {
        ...SpacesFragment
        spaces {
          ...SpacesFragment
          spaces {
            ...SpacesFragment
            spaces {
              ...SpacesFragment
            }
          }
        }
      }
    }
  }
`

export const DELETE_SPACE = gql`
  ${SPACES_FRAGMENT}
  mutation deleteSpace(
    $id: String!
    $parentID: String!
    $workspaceID: String!
  ) {
    deleteSpace(id: $id, parentID: $parentID, workspaceID: $workspaceID) {
      ...SpacesFragment
      spaces {
        ...SpacesFragment
        spaces {
          ...SpacesFragment
          spaces {
            ...SpacesFragment
            spaces {
              ...SpacesFragment
            }
          }
        }
      }
    }
  }
`

export const CREATE_SUB_SPACE = gql`
  ${SPACES_FRAGMENT}
  mutation createSubSpace($id: String!, $input: SpaceInput!) {
    createSubSpace(id: $id, input: $input) {
      ...SpacesFragment
      spaces {
        ...SpacesFragment
        spaces {
          ...SpacesFragment
          spaces {
            ...SpacesFragment
            spaces {
              ...SpacesFragment
            }
          }
        }
      }
    }
  }
`

export const SIGN_IN = gql`
  mutation ($input: EmptyUserInput) {
    signIn(input: $input) {
      id
      fName
      lName
      role
      email
      mobile
      token
      firebaseID
      lastWorkspace {
        id
        name
      }
      profileURL
    }
  }
`
// Sub user
export const CREATE_SUB_USER = gql`
  mutation ($input: SubUserInput!) {
    createSubUser(input: $input) {
      id
      fName
      lName
      email
      role
      designation
      department
      mobile
      employeeID
      userGroup
      lastLoggedIn
      isActive
      workspaces {
        id
        name
      }
      allocationProfiles {
        id
        name
      }
    }
  }
`

export const CREATE_BULK_USERS = gql`
  mutation ($input: [SubUserInput]) {
    createBulkUsers(input: $input) {
      id
      fName
      lName
      email
      role
      designation
      department
      mobile
      employeeID
      userGroup
      lastLoggedIn
      isActive
      workspaces {
        id
        name
      }
      allocationProfiles {
        id
        name
      }
    }
  }
`

export const UPDATE_SUB_USER = gql`
  mutation ($input: SubUserInput!, $id: String!) {
    updateSubUser(input: $input, id: $id) {
      id
      fName
      lName
      email
      role
      designation
      department
      mobile
      employeeID
      userGroup
      lastLoggedIn
      isActive
      workspaces {
        id
        name
      }
      allocationProfiles {
        id
        name
      }
    }
  }
`

export const DELETE_SUB_USER = gql`
  mutation ($id: String!) {
    deleteSubUser(id: $id) {
      id
      fName
      lName
      email
      role
      designation
      department
      mobile
      employeeID
      userGroup
      lastLoggedIn
      isActive
      workspaces {
        id
        name
      }
      allocationProfiles {
        id
        name
      }
    }
  }
`

export const ADD_ASSIGNEE = gql`
  ${TASK_FRAGMENT}
  mutation ($input: AssigneeInput!, $auditInput: AuditInput!) {
    addAssignee(input: $input, auditInput: $auditInput) {
      ...TaskFragment
    }
  }
`

export const REMOVE_ASSIGNEE = gql`
  mutation ($userID: String!, $taskID: String!) {
    removeAssignee(userID: $userID, taskID: $taskID) {
      id
      title
      parentID
      startDate
      endDate
      status
      description
      assignees {
        id
        user {
          fName
          lName
          firebaseID
        }
      }
      subTasks {
        id
        title
        parentID
        startDate
        endDate
        status
        description
      }
      comments {
        id
        user {
          fName
          lName
          email
        }
        comment
        createdAt
        updatedAt
      }
      customFieldValues {
        custFieldID
        value
      }
      audit {
        id
        auditType
        timeStamp
        user {
          fName
        }
        from
        to
      }
    }
  }
`

// Allocation profile
export const CREATE_ALLOCATION_PROFILE = gql`
  mutation ($input: AllocationProfileInput!) {
    createAllocationProfile(input: $input) {
      id
      name
      workspace {
        id
        name
      }
      spaces {
        id
        name
        isFolder
      }
      tenantID
    }
  }
`

export const UPDATE_ALLOCATION_PROFILE = gql`
  mutation ($id: String!, $input: AllocationProfileInput!) {
    updateAllocationProfile(id: $id, input: $input) {
      id
      name
      workspace {
        id
        name
      }
      spaces {
        id
        name
        isFolder
      }
      tenantID
    }
  }
`

export const DELETE_ALLOCATION_PROFILE = gql`
  mutation ($id: String!) {
    deleteAllocationProfile(id: $id) {
      id
      name
      workspace {
        id
        name
      }
      spaces {
        id
        name
        isFolder
      }
      tenantID
    }
  }
`

export const UPDATE_LAST_WORKSPACE = gql`
  mutation ($workspaceID: String!) {
    updateLastWorkspace(workspaceID: $workspaceID) {
      id
      fName
      lName
      email
      role
      designation
      department
      mobile
      employeeID
      userGroup
      lastLoggedIn
      isActive
      workspaces {
        id
        name
      }
      lastWorkspace {
        id
        name
      }
      allocationProfiles {
        id
        name
      }
    }
  }
`

// CUSTOM FIELDS

export const CUSTOM_FIELD_FRAGMENT = gql`
  fragment CustomFieldFragment on CustomField {
    id
    fieldName
    fieldType
    description
    options {
      name
      color
    }
    fromSettings
    visibility
    helperText
    isAllSpaces
    isAllWorkspaces
    workspaceIDs
    spaceIDs
    workspaces {
      name
    }
    spaces {
      name
    }
    active
  }
`

export const ADD_CUSTOM_FIELD = gql`
  ${CUSTOM_FIELD_FRAGMENT}
  mutation ($input: CustomFieldInput!) {
    addCustomField(input: $input) {
      ...CustomFieldFragment
    }
  }
`
export const UPDATE_CUSTOM_FIELD = gql`
  ${TASK_FRAGMENT}
  mutation (
    $id: String!
    $taskID: String!
    $value: String!
    $auditInput: AuditInput!
  ) {
    updateCustomField(
      id: $id
      taskID: $taskID
      value: $value
      auditInput: $auditInput
    ) {
      ...TaskFragment
    }
  }
`

export const EDIT_CUSTOM_FIELD = gql`
  ${CUSTOM_FIELD_FRAGMENT}
  mutation ($fieldID: String!, $input: CustomFieldInput!) {
    editCustomField(fieldID: $fieldID, input: $input) {
      ...CustomFieldFragment
    }
  }
`

export const DELETE_CUSTOM_FIELD = gql`
  mutation ($fieldID: String!, $fromSettings: Boolean!) {
    deleteCustomField(fieldID: $fieldID, fromSettings: $fromSettings)
  }
`

// TASK ORDER

export const CHANGE_TASK_COLUMN_ORDER = gql`
  mutation ($spaceID: String!, $columnOrderIDs: [String!]) {
    changeColumnOrders(spaceID: $spaceID, columnOrderIDs: $columnOrderIDs) {
      columnOrderIDs
    }
  }
`

// TIME TRACK
export const CREATE_NEW_TIME_TRACK = gql`
  mutation ($input: TimeTrackInput) {
    createNewTimeTrack(input: $input) {
      id
      workspace
      type
      billable
      weekRange
      weekDates
      notes
    }
  }
`

export const DELETE_TIME_TRACK = gql`
  mutation ($id: String!) {
    deleteTimeTrack(id: $id) {
      id
      workspace
      type
      billable
      weekRange
      weekDates
      notes
    }
  }
`

export const UPDATE_TIME_TRACK = gql`
  mutation ($id: String!, $input: TimeTrackInput!) {
    updateTimeTrack(id: $id, input: $input) {
      id
      workspace
      type
      billable
      weekRange
      weekDates
      notes
    }
  }
`

export const SUBMIT_TIME_TRACK = gql`
  mutation ($input: [TimeTrackInput!]) {
    submitTimeTrack(input: $input)
  }
`

// DASHBOARD
export const CREATE_DASHBOARD = gql`
  mutation ($input: DashboardInput!) {
    createDashboard(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_DASHBOARD = gql`
  mutation ($id: String!, $input: DashboardInput!) {
    updateDashboard(id: $id, input: $input) {
      id
      name
      expiryDate
      startDate
      endDate
      externalLink
    }
  }
`

export const DELETE_DASHBOARD = gql`
  mutation ($id: String!) {
    deleteDashboard(id: $id) {
      id
      name
    }
  }
`

export const CREATE_WIDGET = gql`
  mutation ($input: WidgetInput!, $workspaceID: String!) {
    createWidget(input: $input, workspaceID: $workspaceID) {
      id
      widgetName
      dataSource
      visualName
      text
      fields {
        id
        spaceName
        fieldName
        options {
          name
        }
      }
      filters {
        fieldID
        type
        values
      }
      datas {
        fieldID
        index
        name
        count
        color
      }
      tableData {
        fieldID
        fieldName
        spaceName
        values {
          taskID
          data
        }
      }
      createdAt
    }
  }
`

export const UPDATE_WIDGET = gql`
  mutation ($id: String!, $input: WidgetInput!, $workspaceID: String!) {
    updateWidget(id: $id, input: $input, workspaceID: $workspaceID) {
      id
      widgetName
      dataSource
      visualName
      text
      fields {
        id
        spaceName
        fieldName
        options {
          name
        }
      }
      filters {
        fieldID
        type
        values
      }
      datas {
        fieldID
        index
        name
        count
        color
      }
      tableData {
        fieldID
        fieldName
        spaceName
        values {
          taskID
          data
        }
      }
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_WIDGET_UI = gql`
  mutation ($id: String!, $visualName: String!, $workspaceID: String!) {
    updateWidgetUI(
      id: $id
      visualName: $visualName
      workspaceID: $workspaceID
    ) {
      id
      widgetName
      dataSource
      visualName
      text
      fields {
        id
        fieldName
        options {
          name
        }
      }
      filters {
        fieldID
        type
        values
      }
      datas {
        fieldID
        index
        name
        count
        color
      }
      tableData {
        fieldID
        fieldName
        values {
          taskID
          data
        }
      }
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_LAYOUT = gql`
  mutation ($id: String!, $layout: String!) {
    updateLayout(id: $id, layout: $layout) {
      layout
    }
  }
`

export const DELETE_WIDGET = gql`
  mutation ($id: String!) {
    deleteWidget(id: $id) {
      id
      widgetName
      dataSource
      visualName
      text
      datas {
        fieldID
        index
        name
        count
        color
      }
    }
  }
`

export const SYNC_WIDGETS = gql`
  mutation ($input: SyncInput!) {
    syncWidgets(input: $input)
  }
`

// NOTE Social accounts API
export const ADD_LINKEDIN = gql`
  mutation ($input: RequestInput!) {
    addLinkedin(input: $input) {
      id
      accountType
      firstName
      lastName
      isActive
      profilePicURL
      description
      following
      user {
        fName
        lName
      }
    }
  }
`
export const ADD_TWITTER = gql`
  mutation ($input: TwitterAccessTokenRequest!) {
    addTwitter(input: $input) {
      id
      accountType
      firstName
      lastName
      isActive
      profilePicURL
      description
      following
      user {
        fName
        lName
      }
    }
  }
`

export const DELETE_ACCOUNT = gql`
  mutation ($accountID: String!) {
    deleteAccount(accountID: $accountID)
  }
`

export const CREATE_POST = gql`
  mutation ($input: PostInput!) {
    createPost(input: $input) {
      id
      start
      isPosted
      isTextPost
      isPicturePost
      isVideoPost
      scheduleDate
      accounts {
        id
        accountType
      }
      socialPosts {
        account {
          id
        }
        postID
        postURL
      }
      user {
        fName
        lName
      }
      subject
      content
      createdAt
      updatedAt
      awsMedias {
        id
        name
        preview
        type
      }
      campaign {
        id
        name
      }
    }
  }
`

export const UPDATE_POST = gql`
  mutation ($id: String!, $input: PostInput!) {
    updatePost(id: $id, input: $input) {
      id
      start
      isPosted
      isTextPost
      isPicturePost
      isVideoPost
      scheduleDate
      accounts {
        id
        accountType
      }
      socialPosts {
        account {
          id
        }
        postID
        postURL
      }
      user {
        fName
        lName
      }
      subject
      content
      createdAt
      updatedAt
      awsMedias {
        id
        name
        preview
        type
      }
      campaign {
        id
        name
      }
    }
  }
`

export const DELETE_POST = gql`
  mutation ($id: String!) {
    deletePost(id: $id)
  }
`

export const SCHEDULE_POST = gql`
  mutation ($input: PostInput!) {
    schedulePost(input: $input) {
      id
      start
      isPosted
      isTextPost
      isPicturePost
      isVideoPost
      scheduleDate
      accounts {
        id
        accountType
      }
      socialPosts {
        account {
          id
        }
        postID
        postURL
      }
      user {
        fName
        lName
      }
      subject
      content
      createdAt
      updatedAt
      awsMedias {
        id
        name
        preview
        type
      }
    }
  }
`

// NOTE CAMPAIGNS

export const CAMPAIGN_FRAGMENT = gql`
  fragment CampaignFragment on Campaign {
    id
    name
    description
    startDate
    endDate
    isEvergreen
    status
    isAllWorkspace
    workspaces {
      id
    }
    user {
      id
    }
  }
`

export const CREATE_CAMPAIGN = gql`
  ${CAMPAIGN_FRAGMENT}
  mutation ($input: CampaignInput!) {
    createCampaign(input: $input) {
      ...CampaignFragment
    }
  }
`

export const UPDATE_CAMPAIGN = gql`
  ${CAMPAIGN_FRAGMENT}
  mutation ($id: String!, $input: CampaignInput!) {
    updateCampaign(id: $id, input: $input) {
      ...CampaignFragment
    }
  }
`

export const DELETE_CAMPAIGN = gql`
  mutation ($id: String!) {
    deleteCampaign(id: $id)
  }
`

// Notifications
export const CLEAR_ALL_NOTIFICATIONS = gql`
  mutation {
    clearAllNotifications
  }
`
export const READ_ALL_NOTIFICATIONS = gql`
  mutation {
    readAllNotifications
  }
`

export const READ_NOTIFICATION = gql`
  mutation ($id: String!) {
    readNotification(id: $id) {
      id
      from {
        fName
      }
      to {
        fName
      }
      type {
        id
        taskID
        title
        spaceID
        workspaceID
      }
      timeStamp
      isRead
    }
  }
`

// Settings
export const UPDATE_SETTINGS = gql`
  mutation ($input: SettingsInput!, $id: String!) {
    updateSettings(input: $input, id: $id) {
      id
      notificationSound
      notificationPopup
    }
  }
`

// Task Filters
export const ADD_TASK_FILTER = gql`
  ${TASK_FRAGMENT}
  mutation ($input: TaskFilterInput!) {
    addTaskFilter(input: $input) {
      tasks {
        ...TaskFragment
      }
      after
      filter {
        id
        where
        filters {
          type
          condition
          options
        }
      }
      totalLength
    }
  }
`

export const UPDATE_TASK_FILTER = gql`
  ${TASK_FRAGMENT}
  mutation ($id: String!, $input: TaskFilterInput!) {
    updateTaskFilter(id: $id, input: $input) {
      tasks {
        ...TaskFragment
      }
      after
      filter {
        id
        where
        filters {
          type
          condition
          options
        }
      }
      totalLength
    }
  }
`

export const DELETE_TASK_FILTER = gql`
  ${TASK_FRAGMENT}
  mutation ($id: String!) {
    deleteTaskFilter(id: $id) {
      tasks {
        ...TaskFragment
      }
      after
      filter {
        id
        where
        filters {
          type
          condition
          options
        }
      }
      totalLength
    }
  }
`
export const CREATE_EXTERNAL_DASHBOARD = gql`
  mutation ($input: ExternalDashboardInput!) {
    createExternalDashboard(input: $input) {
      id
      dashboardID
      link
      expiryDate
    }
  }
`

export const DELETE_EXTERNAL_DASHBOARD = gql`
  mutation ($dashboardID: String!) {
    deleteExternalDashboard(dashboardID: $dashboardID)
  }
`

export const UPDATE_EXTERNAL_DASHBOARD = gql`
  mutation ($dashboardID: String!) {
    updateExternalDashboard(dashboardID: $dashboardID) {
      id
      dashboardID
      link
      expiryDate
    }
  }
`

export const UPLOAD_PROFILE_PICTURE = gql`
  mutation ($url: String!) {
    updateProfilePicture(url: $url)
  }
`

export const REMOVE_PROFILE_PICTURE = gql`
  mutation {
    removeProfilePicture
  }
`
