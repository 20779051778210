import React from "react"
import toast, { Toaster } from "react-hot-toast"

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import {
  CREATE_SPACE,
  UPDATE_SPACE,
  CREATE_SUB_SPACE,
} from "../../graphql/mutation"
import { useMutation } from "@apollo/client"

const SpaceModal = ({
  showSpaceModal,
  handleCloseSpaceModal,
  workspaceID,
  handleUpdateSpaces,
  space,
}) => {
  // SECTION APIS
  const [createSpace] = useMutation(CREATE_SPACE, {
    onCompleted: data => {
      if (data && data.createSpace) {
        handleUpdateSpaces(data.createSpace)
        handleCloseSpaceModal()
      }
    },
    onError: error => {
      console.log("error", error)
      toast.success(`${error.message}`, {
        position: "top-right",
      })
    },
  })

  const [createSubSpace] = useMutation(CREATE_SUB_SPACE, {
    onCompleted: data => {
      if (data && data.createSubSpace) {
        handleUpdateSpaces(data.createSubSpace)
        handleCloseSpaceModal()
      }
    },
    onError: error => {
      console.log("error", error)
      toast.success(`${error.message}`, {
        position: "top-right",
      })
    },
  })

  const [updateSpace] = useMutation(UPDATE_SPACE, {
    onCompleted: data => {
      if (data && data.updateSpace) {
        handleUpdateSpaces(data.updateSpace)
        handleCloseSpaceModal()
      }
    },
    onError: error => {
      console.log("error", error)
      toast.success(`${error.message}`, {
        position: "top-right",
      })
    },
  })

  const handleSubmit = values => {
    let spaceInput = {
      name: values.name,
      isFolder: space ? space.isFolder : true,
      workspaceID,
    }

    if (space && space.update) {
      // Update space
      updateSpace({
        variables: {
          id: space.id,
          input: spaceInput,
        },
      })
    } else if (space) {
      // Create new space/list
      createSubSpace({
        variables: {
          id: space.id,
          input: spaceInput,
        },
      })
    } else {
      // Create New Space
      createSpace({
        variables: {
          input: spaceInput,
        },
      })
    }
  }

  return (
    <Modal isOpen={showSpaceModal}>
      <AvForm
        onValidSubmit={(e, v) => {
          handleSubmit(v)
        }}
      >
        <ModalHeader toggle={handleCloseSpaceModal} tag="h4">
          {space
            ? space?.update
              ? `Update "${space?.name}" name`
              : `Create a new ${space?.isFolder ? "space" : "list"} under "${
                  space?.name
                }"`
            : "Create a new space"}
        </ModalHeader>
        <ModalBody>
          <AvField
            style={{
              borderRadius: "8px",
            }}
            name="name"
            label="Name"
            type="text"
            validate={{
              required: { value: true, message: "Please enter space name" },
            }}
          />
        </ModalBody>
        <ModalFooter>
          <div
            onClick={handleCloseSpaceModal}
            className="btn btn-outline-secondary rounded-3"
          >
            Cancel
          </div>
          <button type="submit" className="btn btn-success rounded-3">
            Create
          </button>
        </ModalFooter>
      </AvForm>
      <Toaster />
    </Modal>
  )
}

export default SpaceModal
